/*
 This file is part of GNU Taler
 (C) 2019-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 * Type and schema definitions for notifications from the wallet to clients
 * of the wallet.
 */

/**
 * Imports.
 */
import { TransactionState } from "./transactions-types.js";
import { TalerErrorDetail } from "./wallet-types.js";

export enum NotificationType {
  BalanceChange = "balance-change",
  ExchangeOperationError = "exchange-operation-error",
  ExchangeAdded = "exchange-added",
  BackupOperationError = "backup-error",
  PendingOperationProcessed = "pending-operation-processed",
  TransactionStateTransition = "transaction-state-transition",
}

export interface ErrorInfoSummary {
  code: number;
  hint?: string;
  message?: string;
}

export interface TransactionStateTransitionNotification {
  type: NotificationType.TransactionStateTransition;
  transactionId: string;
  oldTxState: TransactionState;
  newTxState: TransactionState;
  errorInfo?: ErrorInfoSummary;
}

export interface ExchangeAddedNotification {
  type: NotificationType.ExchangeAdded;
}

export interface BalanceChangeNotification {
  type: NotificationType.BalanceChange;
}

export interface ExchangeOperationErrorNotification {
  type: NotificationType.ExchangeOperationError;
  error: TalerErrorDetail;
}

export interface BackupOperationErrorNotification {
  type: NotificationType.BackupOperationError;
  error: TalerErrorDetail;
}


export interface PendingOperationProcessedNotification {
  type: NotificationType.PendingOperationProcessed;
  id: string;
}


export type WalletNotification =
  | BalanceChangeNotification
  | BackupOperationErrorNotification
  | ExchangeAddedNotification
  | ExchangeOperationErrorNotification
  | PendingOperationProcessedNotification
  | TransactionStateTransitionNotification;
