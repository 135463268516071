import { AbsoluteTime } from "@gnu-taler/taler-util";
import { InputLine, UIFormProps } from "./InputLine.js";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { VNode, h } from "preact";
import { format, parse } from "date-fns";

export function InputDate<T extends object, K extends keyof T>(
  props: { pattern?: string } & UIFormProps<T, K>,
): VNode {
  const pattern = props.pattern ?? "dd/MM/yyyy";
  return (
    <InputLine<T, K>
      type="text"
      after={{
        type: "icon",
        icon: <CalendarIcon class="h-6 w-6" />,
      }}
      converter={{
        //@ts-ignore
        fromStringUI: (v): AbsoluteTime => {
          if (!v) return AbsoluteTime.never();
          const t_ms = parse(v, pattern, Date.now()).getTime();
          return AbsoluteTime.fromMilliseconds(t_ms);
        },
        //@ts-ignore
        toStringUI: (v: AbsoluteTime) => {
          return !v || !v.t_ms
            ? ""
            : v.t_ms === "never"
            ? "never"
            : format(v.t_ms, pattern);
        },
      }}
      {...props}
    />
  );
}
