import { ErrorType, HttpResponsePaginated, RequestError, notify, notifyError, useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h, Fragment } from "preact";
import { useAccountDetails } from "../../hooks/access.js";
import { useAdminAccountAPI } from "../../hooks/circuit.js";
import { Amounts, HttpStatusCode, TranslatedString } from "@gnu-taler/taler-util";
import { buildRequestErrorMessage, undefinedIfEmpty } from "../../utils.js";
import { useEffect, useRef, useState } from "preact/hooks";
import { ShowInputErrorLabel } from "../../components/ShowInputErrorLabel.js";
import { Attention } from "../../components/Attention.js";
import { doAutoFocus } from "../PaytoWireTransferForm.js";

export function RemoveAccount({
  account,
  onCancel,
  onUpdateSuccess,
  onLoadNotOk,
  focus,
}: {
  onLoadNotOk: <T>(
    error: HttpResponsePaginated<T, SandboxBackend.SandboxError>,
  ) => VNode;
  focus?: boolean;
  onCancel: () => void;
  onUpdateSuccess: () => void;
  account: string;
}): VNode {
  const { i18n } = useTranslationContext();
  const result = useAccountDetails(account);
  const [accountName, setAccountName] = useState<string | undefined>()
  const { deleteAccount } = useAdminAccountAPI();

  if (!result.ok) {
    if (result.loading || result.type === ErrorType.TIMEOUT) {
      return onLoadNotOk(result);
    }
    if (result.status === HttpStatusCode.NotFound) {
      return <div>account not found</div>;
    }
    return onLoadNotOk(result);
  }
  const balance = Amounts.parse(result.data.balance.amount);
  if (!balance) {
    return <div>there was an error reading the balance</div>;
  }
  const isBalanceEmpty = Amounts.isZero(balance);
  if (!isBalanceEmpty) {
    return <Attention type="warning" title={i18n.str`Can't delete the account`} onClose={onCancel}>
      <i18n.Translate>The account can't be delete while still holding some balance. First make sure that the owner make a complete cashout.</i18n.Translate>
    </Attention>
  }

  async function doRemove() {
    try {
      const r = await deleteAccount(account);
      onUpdateSuccess();
    } catch (error) {
      if (error instanceof RequestError) {
        notify(
          buildRequestErrorMessage(i18n, error.cause, {
            onClientError: (status) =>
              status === HttpStatusCode.Forbidden
                ? i18n.str`The administrator specified a institutional username`
                : status === HttpStatusCode.NotFound
                  ? i18n.str`The username was not found`
                  : status === HttpStatusCode.PreconditionFailed
                    ? i18n.str`Balance was not zero`
                    : undefined,
          }),
        );
      } else {
        notifyError(i18n.str`Operation failed, please report`,
          (error instanceof Error
            ? error.message
            : JSON.stringify(error)) as TranslatedString);
      }
    }
  }

  const errors = undefinedIfEmpty({
    accountName: !accountName
      ? i18n.str`required`
      : account !== accountName
        ? i18n.str`name doesn't match`
        : undefined,
  });


  return (
    <div>
      <Attention type="warning" title={i18n.str`You are going to remove the account`}>
        <i18n.Translate>This step can't be undone.</i18n.Translate>
      </Attention>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 bg-gray-100 my-4 px-4 pb-4 rounded-lg">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            <i18n.Translate>Deleting account "{account}"</i18n.Translate>
          </h2>
        </div>
        <form
          class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
          autoCapitalize="none"
          autoCorrect="off"
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

              <div class="sm:col-span-5">
                <label
                  class="block text-sm font-medium leading-6 text-gray-900"
                  for="password"
                >
                  {i18n.str`Verification`}
                </label>
                <div class="mt-2">
                  <input
                    ref={focus ? doAutoFocus : undefined}
                    type="text"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 data-[error=true]:ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    name="password"
                    id="password"
                    data-error={!!errors?.accountName && accountName !== undefined}
                    value={accountName ?? ""}
                    onChange={(e) => {
                      setAccountName(e.currentTarget.value)
                    }}
                    placeholder={account}
                    autocomplete="off"
                  />
                  <ShowInputErrorLabel
                    message={errors?.accountName}
                    isDirty={accountName !== undefined}
                  />
                </div>
                <p class="mt-2 text-sm text-gray-500" >
                  <i18n.Translate>enter the account name that is going to be deleted</i18n.Translate>
                </p>
              </div>



            </div>
          </div>
          <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            {onCancel ?
              <button type="button" class="text-sm font-semibold leading-6 text-gray-900"
                onClick={onCancel}
              >
                <i18n.Translate>Cancel</i18n.Translate>
              </button>
              : <div />
            }
            <button type="submit"
              class="disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              disabled={!!errors}
              onClick={(e) => {
                e.preventDefault()
                doRemove()
              }}
            >
              <i18n.Translate>Delete</i18n.Translate>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
