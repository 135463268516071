/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  Codec,
  buildCodecForObject,
  buildCodecForUnion,
  canonicalizeBaseUrl,
  codecForBoolean,
  codecForConstString,
  codecForString,
} from "@gnu-taler/taler-util";
import {
  ErrorType,
  HttpError,
  RequestError,
  buildStorageKey,
  useLocalStorage,
} from "@gnu-taler/web-util/browser";
import {
  HttpResponse,
  HttpResponseOk,
  RequestOptions,
} from "@gnu-taler/web-util/browser";
import { useApiContext } from "@gnu-taler/web-util/browser";
import { useCallback, useEffect, useState } from "preact/hooks";
import { useSWRConfig } from "swr";
import { useBackendContext } from "../context/backend.js";
import { bankUiSettings } from "../settings.js";
import { AccessToken } from "./useCredentialsChecker.js";

/**
 * Has the information to reach and
 * authenticate at the bank's backend.
 */
export type BackendState = LoggedIn | LoggedOut | Expired;

interface LoggedIn {
  status: "loggedIn";
  isUserAdministrator: boolean;
  username: string;
  token: AccessToken;
}
interface Expired {
  status: "expired";
  isUserAdministrator: boolean;
  username: string;
}
interface LoggedOut {
  status: "loggedOut";
}

export const codecForBackendStateLoggedIn = (): Codec<LoggedIn> =>
  buildCodecForObject<LoggedIn>()
    .property("status", codecForConstString("loggedIn"))
    .property("username", codecForString())
    .property("token", codecForString() as Codec<AccessToken>)
    .property("isUserAdministrator", codecForBoolean())
    .build("BackendState.LoggedIn");

export const codecForBackendStateExpired = (): Codec<Expired> =>
  buildCodecForObject<Expired>()
    .property("status", codecForConstString("expired"))
    .property("username", codecForString())
    .property("isUserAdministrator", codecForBoolean())
    .build("BackendState.Expired");

export const codecForBackendStateLoggedOut = (): Codec<LoggedOut> =>
  buildCodecForObject<LoggedOut>()
    .property("status", codecForConstString("loggedOut"))
    .build("BackendState.LoggedOut");

export const codecForBackendState = (): Codec<BackendState> =>
  buildCodecForUnion<BackendState>()
    .discriminateOn("status")
    .alternative("loggedIn", codecForBackendStateLoggedIn())
    .alternative("loggedOut", codecForBackendStateLoggedOut())
    .alternative("expired", codecForBackendStateExpired())
    .build("BackendState");

export function getInitialBackendBaseURL(): string {
  const overrideUrl =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("bank-base-url")
      : undefined;
  let result: string;
  if (!overrideUrl) {
    //normal path
    if (!bankUiSettings.backendBaseURL) {
      console.error(
        "ERROR: backendBaseURL was overridden by a setting file and missing. Setting value to 'window.origin'",
      );
      result = window.origin
    } else {
      result = bankUiSettings.backendBaseURL;
    }
  } else {
    // testing/development path
    result = overrideUrl
  }
  try {
    return canonicalizeBaseUrl(result)
  } catch (e) {
    //fall back
    return canonicalizeBaseUrl(window.origin)
  }
}

export const defaultState: BackendState = {
  status: "loggedOut",
};

export interface BackendStateHandler {
  state: BackendState;
  logOut(): void;
  expired(): void;
  logIn(info: {username: string, token: AccessToken}): void;
}

const BACKEND_STATE_KEY = buildStorageKey(
  "backend-state",
  codecForBackendState(),
);

/**
 * Return getters and setters for
 * login credentials and backend's
 * base URL.
 */
export function useBackendState(): BackendStateHandler {
  const { value: state, update } = useLocalStorage(
    BACKEND_STATE_KEY,
    defaultState,
  );
  const mutateAll = useMatchMutate();

  return {
    state,
    logOut() {
      update(defaultState);
    },
    expired() {
      if (state.status === "loggedOut") return;
      const nextState: BackendState = {
        status: "expired",
        username: state.username,
        isUserAdministrator: state.username === "admin",
      };
      update(nextState);
    },
    logIn(info) {
      //admin is defined by the username
      const nextState: BackendState = {
        status: "loggedIn",
        ...info,
        isUserAdministrator: info.username === "admin",
      };
      update(nextState);
      mutateAll(/.*/)
    },
  };
}

interface useBackendType {
  request: <T>(
    path: string,
    options?: RequestOptions,
  ) => Promise<HttpResponseOk<T>>;
  fetcher: <T>(endpoint: string) => Promise<HttpResponseOk<T>>;
  multiFetcher: <T>(endpoint: string[][]) => Promise<HttpResponseOk<T>[]>;
  paginatedFetcher: <T>(
    args: [string, string | undefined, number],
  ) => Promise<HttpResponseOk<T>>;
  sandboxAccountsFetcher: <T>(
    args: [string, number, number, string],
  ) => Promise<HttpResponseOk<T>>;
  sandboxCashoutFetcher: <T>(endpoint: string[]) => Promise<HttpResponseOk<T>>;
}
export function usePublicBackend(): useBackendType {
  const { request: requestHandler } = useApiContext();

  const baseUrl = getInitialBackendBaseURL();

  const request = useCallback(
    function requestImpl<T>(
      path: string,
      options: RequestOptions = {},
    ): Promise<HttpResponseOk<T>> {
      return requestHandler<T>(baseUrl, path, options);
    },
    [baseUrl],
  );

  const fetcher = useCallback(
    function fetcherImpl<T>(endpoint: string): Promise<HttpResponseOk<T>> {
      return requestHandler<T>(baseUrl, endpoint);
    },
    [baseUrl],
  );
  const paginatedFetcher = useCallback(
    function fetcherImpl<T>([endpoint, start, size]: [
      string,
      string | undefined,
      number,
    ]): Promise<HttpResponseOk<T>> {
      const delta = -1 * size //descending order
      const params = start ? { delta, start } : { delta }
      return requestHandler<T>(baseUrl, endpoint, {
        params,
      });
    },
    [baseUrl],
  );
  const multiFetcher = useCallback(
    function multiFetcherImpl<T>([endpoints]: string[][]): Promise<
      HttpResponseOk<T>[]
    > {
      return Promise.all(
        endpoints.map((endpoint) => requestHandler<T>(baseUrl, endpoint)),
      );
    },
    [baseUrl],
  );
  const sandboxAccountsFetcher = useCallback(
    function fetcherImpl<T>([endpoint, page, size, account]: [
      string,
      number,
      number,
      string,
    ]): Promise<HttpResponseOk<T>> {
      return requestHandler<T>(baseUrl, endpoint, {
        params: { page: page || 1, size },
      });
    },
    [baseUrl],
  );
  const sandboxCashoutFetcher = useCallback(
    function fetcherImpl<T>([endpoint, account]: string[]): Promise<
      HttpResponseOk<T>
    > {
      return requestHandler<T>(baseUrl, endpoint);
    },
    [baseUrl],
  );
  return {
    request,
    fetcher,
    paginatedFetcher,
    multiFetcher,
    sandboxAccountsFetcher,
    sandboxCashoutFetcher,
  };
}

type CheckResult = ValidResult | RequestInvalidResult | InvalidationResult;

interface ValidResult {
  valid: true;
}
interface RequestInvalidResult {
  valid: false;
  requestError: true;
  cause: RequestError<any>["cause"];
}
interface InvalidationResult {
  valid: false;
  requestError: false;
  error: unknown;
}

export function useAuthenticatedBackend(): useBackendType {
  const { state } = useBackendContext();
  const { request: requestHandler } = useApiContext();

  // FIXME: libeufin returns 400 insteand of 401 if there is no auth token
  const creds = state.status === "loggedIn" ? state.token : "secret-token:a"; 
  const baseUrl = getInitialBackendBaseURL();

  const request = useCallback(
    function requestImpl<T>(
      path: string,
      options: RequestOptions = {},
    ): Promise<HttpResponseOk<T>> {
      return requestHandler<T>(baseUrl, path, { token: creds, ...options });
    },
    [baseUrl, creds],
  );

  const fetcher = useCallback(
    function fetcherImpl<T>(endpoint: string): Promise<HttpResponseOk<T>> {
      return requestHandler<T>(baseUrl, endpoint, { token: creds });
    },
    [baseUrl, creds],
  );
  const paginatedFetcher = useCallback(
    function fetcherImpl<T>([endpoint, start, size]: [
      string,
      string | undefined,
      number,
    ]): Promise<HttpResponseOk<T>> {
      const delta = -1 * size //descending order
      const params = start ? { delta, start } : { delta }
      return requestHandler<T>(baseUrl, endpoint, {
        token: creds,
        params,
      });
    },
    [baseUrl, creds],
  );
  const multiFetcher = useCallback(
    function multiFetcherImpl<T>([endpoints]: string[][]): Promise<
      HttpResponseOk<T>[]
    > {
      return Promise.all(
        endpoints.map((endpoint) =>
          requestHandler<T>(baseUrl, endpoint, { token: creds }),
        ),
      );
    },
    [baseUrl, creds],
  );
  const sandboxAccountsFetcher = useCallback(
    function fetcherImpl<T>([endpoint, page, size, account]: [
      string,
      number,
      number,
      string,
    ]): Promise<HttpResponseOk<T>> {
      return requestHandler<T>(baseUrl, endpoint, {
        token: creds,
        params: { page: page || 1, size },
      });
    },
    [baseUrl],
  );

  const sandboxCashoutFetcher = useCallback(
    function fetcherImpl<T>([endpoint, account]: string[]): Promise<
      HttpResponseOk<T>
    > {
      return requestHandler<T>(baseUrl, endpoint, {
        token: creds,
        params: { account },
      });
    },
    [baseUrl, creds],
  );
  return {
    request,
    fetcher,
    paginatedFetcher,
    multiFetcher,
    sandboxAccountsFetcher,
    sandboxCashoutFetcher,
  };
}
/**
 *
 * @deprecated
 */
export function useBackendConfig(): HttpResponse<
  SandboxBackend.Config,
  SandboxBackend.SandboxError
> {
  const { request } = usePublicBackend();

  type Type = SandboxBackend.Config;

  const [result, setResult] = useState<
    HttpResponse<Type, SandboxBackend.SandboxError>
  >({ loading: true });

  useEffect(() => {
    request<Type>(`/config`)
      .then((data) => setResult(data))
      .catch((error: RequestError<SandboxBackend.SandboxError>) =>
        setResult(error.cause),
      );
  }, [request]);

  return result;
}

export function useMatchMutate(): (
  re: RegExp,
  value?: unknown,
) => Promise<any> {
  const { cache, mutate } = useSWRConfig();

  if (!(cache instanceof Map)) {
    throw new Error(
      "matchMutate requires the cache provider to be a Map instance",
    );
  }

  return function matchRegexMutate(re: RegExp, value?: unknown) {
    const allKeys = Array.from(cache.keys());
    const keys = allKeys.filter((key) => re.test(key));
    const mutations = keys.map((key) => {
      return mutate(key, value, true);
    });
    return Promise.all(mutations);
  };
}
