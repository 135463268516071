import { h, VNode } from "preact";
import { useBusinessAccounts } from "../../hooks/circuit.js";
import { handleNotOkResult } from "../HomePage.js";
import { AccountAction } from "./Home.js";
import { Amounts } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { RenderAmount } from "../PaytoWireTransferForm.js";

interface Props {
  onAction: (type: AccountAction, account: string) => void;
  account: string | undefined;
  onCreateAccount: () => void;
}

export function AccountList({ account, onAction, onCreateAccount }: Props): VNode {
  const result = useBusinessAccounts({ account });
  const { i18n } = useTranslationContext();

  if (result.loading) return <div />;
  if (!result.ok) {
    return handleNotOkResult(i18n)(result);
  }

  const { customers } = result.data;
  return <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          <i18n.Translate>Accounts</i18n.Translate>
        </h1>
        <p class="mt-2 text-sm text-gray-700">
          <i18n.Translate>A list of all business account in the bank.</i18n.Translate>
        </p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={(e) => {
            e.preventDefault()
            onCreateAccount()
          }}>
          <i18n.Translate>Create account</i18n.Translate>
        </button>
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {!customers.length ? (
            <div></div>
          ) : (
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">{i18n.str`Username`}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{i18n.str`Name`}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{i18n.str`Balance`}</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">{i18n.str`Actions`}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                {customers.map((item, idx) => {
                  const balance = !item.balance
                    ? undefined
                    : Amounts.parse(item.balance.amount);
                  const balanceIsDebit =
                    item.balance &&
                    item.balance.credit_debit_indicator == "debit";

                  return <tr key={idx}>
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <a href="#" class="text-indigo-600 hover:text-indigo-900"
                        onClick={(e) => {
                          e.preventDefault();
                          onAction("show-details", item.username)
                        }}
                      >
                        {item.username}
                      </a>


                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {item.name}
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {!balance ? (
                        i18n.str`unknown`
                      ) : (
                        <span class="amount">
                          <RenderAmount value={balance} negative={balanceIsDebit} />
                        </span>
                      )}
                    </td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a href="#" class="text-indigo-600 hover:text-indigo-900"
                        onClick={(e) => {
                          e.preventDefault();
                          onAction("update-password", item.username)
                        }}
                      >
                        change password
                      </a>
                      <br />
                      <a href="#" class="text-indigo-600 hover:text-indigo-900" onClick={(e) => {
                        e.preventDefault();
                        onAction("show-cashout", item.username)
                      }}
                      >
                        cashouts
                      </a>
                      <br />
                      <a href="#" class="text-indigo-600 hover:text-indigo-900" onClick={(e) => {
                        e.preventDefault();
                        onAction("remove-account", item.username)
                      }}
                      >
                        remove
                      </a>
                    </td>
                  </tr>
                })}

              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  </div>
}