import { RequestError, notify, notifyError, useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h, Fragment } from "preact";
import { useAdminAccountAPI } from "../../hooks/circuit.js";
import { useState } from "preact/hooks";
import { buildRequestErrorMessage } from "../../utils.js";
import { HttpStatusCode, TranslatedString } from "@gnu-taler/taler-util";
import { getRandomPassword } from "../rnd.js";
import { AccountForm } from "./AccountForm.js";

export function CreateNewAccount({
  onCancel,
  onCreateSuccess,
}: {
  onCancel: () => void;
  onCreateSuccess: (password: string) => void;
}): VNode {
  const { i18n } = useTranslationContext();
  const { createAccount } = useAdminAccountAPI();
  const [submitAccount, setSubmitAccount] = useState<
    SandboxBackend.Circuit.CircuitAccountData | undefined
  >();

  async function doCreate() {
    if (!submitAccount) return;
    try {
      const account: SandboxBackend.Circuit.CircuitAccountRequest =
      {
        cashout_address: submitAccount.cashout_address,
        contact_data: submitAccount.contact_data,
        internal_iban: submitAccount.iban,
        name: submitAccount.name,
        username: submitAccount.username,
        password: getRandomPassword(),
      };

      await createAccount(account);
      onCreateSuccess(account.password);
    } catch (error) {
      if (error instanceof RequestError) {
        notify(
          buildRequestErrorMessage(i18n, error.cause, {
            onClientError: (status) =>
              status === HttpStatusCode.Forbidden
                ? i18n.str`The rights to perform the operation are not sufficient`
                : status === HttpStatusCode.BadRequest
                  ? i18n.str`Server replied that input data was invalid`
                  : status === HttpStatusCode.Conflict
                    ? i18n.str`At least one registration detail was not available`
                    : undefined,
          }),
        );
      } else {
        notifyError(
          i18n.str`Operation failed, please report`,
          (error instanceof Error
            ? error.message
            : JSON.stringify(error)) as TranslatedString
        )
      }
    }
  }

  return (
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 bg-gray-100 my-4 px-4 pb-4 rounded-lg">
      <div class="px-4 sm:px-0">
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          <i18n.Translate>New business account</i18n.Translate>
        </h2>
      </div>
      <AccountForm
        template={undefined}
        purpose="create"
        onChange={(a) => {
          setSubmitAccount(a);
        }}
      >
        <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          {onCancel ?
            <button type="button" class="text-sm font-semibold leading-6 text-gray-900"
              onClick={onCancel}
            >
              <i18n.Translate>Cancel</i18n.Translate>
            </button>
            : <div />
          }
          <button type="submit"
            class="disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={!submitAccount}
            onClick={(e) => {
              e.preventDefault()
              doCreate()
            }}
          >
            <i18n.Translate>Create</i18n.Translate>
          </button>
        </div>

      </AccountForm>
    </div>
  );
}
