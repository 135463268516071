/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { Amounts, HttpStatusCode, parsePaytoUri } from "@gnu-taler/taler-util";
import { ErrorType, notifyError, useTranslationContext } from "@gnu-taler/web-util/browser";
import { useBackendContext } from "../../context/backend.js";
import { useAccountDetails } from "../../hooks/access.js";
import { Props, State } from "./index.js";

export function useComponentState({ account, goToBusinessAccount, goToConfirmOperation }: Props): State {
  const result = useAccountDetails(account);
  const backend = useBackendContext();
  const { i18n } = useTranslationContext();

  if (result.loading) {
    return {
      status: "loading",
      error: undefined,
    };
  }

  if (!result.ok) {
    if (result.loading || result.type === ErrorType.TIMEOUT) {
      return {
        status: "loading-error",
        error: result,
      };
    }
    //logout if there is any error, not if loading
    // backend.logOut();
    if (result.status === HttpStatusCode.NotFound) {
      notifyError(i18n.str`Username or account label "${account}" not found`, undefined);
      return {
        status: "error-user-not-found",
        error: result,
      };
    }
    if (result.status === HttpStatusCode.Unauthorized) {
      notifyError(i18n.str`Authorization denied`, i18n.str`Maybe the session has expired, login again.`);
      return {
        status: "error-user-not-found",
        error: result,
      };
    }
    return {
      status: "loading-error",
      error: result,
    };
  }

  const { data } = result;

  const balance = Amounts.parseOrThrow(data.balance.amount);

  const debitThreshold = Amounts.parseOrThrow(data.debit_threshold);
  const payto = parsePaytoUri(data.payto_uri);

  if (!payto || !payto.isKnown || (payto.targetType !== "iban" && payto.targetType !== "x-taler-bank")) {
    return {
      status: "invalid-iban",
      error: result
    };
  }

  const balanceIsDebit = data.balance.credit_debit_indicator == "debit";
  const limit = balanceIsDebit
    ? Amounts.sub(debitThreshold, balance).amount
    : Amounts.add(balance, debitThreshold).amount;


  return {
    status: "ready",
    goToBusinessAccount,
    goToConfirmOperation,
    error: undefined,
    account,
    limit,
  };
}
