
import { ComponentChildren, Fragment, h } from "preact";
import { FormProvider, FormState } from "./FormProvider.js";
import { DoubleColumnForm, RenderAllFieldsByUiConfig } from "./forms.js";


export interface FlexibleForm<T extends object> {
  versionId: string;
  design: DoubleColumnForm;
  behavior: (form: Partial<T>) => FormState<T>;
}

export function DefaultForm<T extends object>({
  initial,
  onUpdate,
  form,
  onSubmit,
  children,
}: {
  children?: ComponentChildren;
  initial: Partial<T>;
  onSubmit?: (v: Partial<T>) => void;
  form: FlexibleForm<T>;
  onUpdate?: (d: Partial<T>) => void;
}) {
  return (
    <FormProvider
      initialValue={initial}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      computeFormState={form.behavior}
    >
      <div class="space-y-10 divide-y -mt-5 divide-gray-900/10">
        {form.design.map((section, i) => {
          if (!section) return <Fragment />;
          return (
            <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-5 md:grid-cols-3">
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">
                  {section.title}
                </h2>
                {section.description && (
                  <p class="mt-1 text-sm leading-6 text-gray-600">
                    {section.description}
                  </p>
                )}
              </div>
              <div class="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md md:col-span-2">
                <div class="p-3">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <RenderAllFieldsByUiConfig
                      key={i}
                      fields={section.fields}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {children}
    </FormProvider>
  );
}
