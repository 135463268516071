/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { h, VNode } from "preact";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { State } from "./index.js";
import { format } from "date-fns";
import { Amounts } from "@gnu-taler/taler-util";
import { RenderAmount } from "../../pages/PaytoWireTransferForm.js";

export function LoadingUriView({ error }: State.LoadingUriError): VNode {
  const { i18n } = useTranslationContext();

  return (
    <div>
      <i18n.Translate>Could not load</i18n.Translate>
    </div>
  );
}

export function ReadyView({ cashouts, onSelected }: State.Ready): VNode {
  const { i18n } = useTranslationContext();
  if (!cashouts.length) {
    return (
      <div>
        <i18n.Translate>No cashout at the moment</i18n.Translate>
      </div>
    );
  }
  return (
    <div class="results">
      <table class="pure-table pure-table-striped">
        <thead>
          <tr>
            <th>{i18n.str`Created`}</th>
            <th>{i18n.str`Confirmed`}</th>
            <th>{i18n.str`Total debit`}</th>
            <th>{i18n.str`Total credit`}</th>
            <th>{i18n.str`Status`}</th>
            <th>{i18n.str`Subject`}</th>
          </tr>
        </thead>
        <tbody>
          {cashouts.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{format(item.creation_time, "dd/MM/yyyy HH:mm:ss")}</td>
                <td>
                  {item.confirmation_time
                    ? format(item.confirmation_time, "dd/MM/yyyy HH:mm:ss")
                    : "-"}
                </td>
                <td><RenderAmount value={Amounts.parseOrThrow(item.amount_debit)} /></td>
                <td><RenderAmount value={Amounts.parseOrThrow(item.amount_credit)} /></td>
                <td>{item.status}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onSelected(item.id);
                    }}
                  >
                    {item.subject}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
