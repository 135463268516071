/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/*eslint quote-props: ["error", "consistent"]*/
export const strings: { [s: string]: any } = {};

strings["de"] = {
  domain: "messages",
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=(n != 1);",
        lang: "de",
      },
      Logout: [""],
      "Skip to main content": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "Taler logo": [""],
      "Missing username": [""],
      "Missing password": [""],
      "Please login!": [""],
      "Username:": [""],
      "Password:": [""],
      Login: [""],
      Register: [""],
      "Missing IBAN": [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "Missing subject": [""],
      "Missing amount": [""],
      "Amount is not valid": [""],
      "Should be greater than 0": [""],
      "Receiver IBAN:": [""],
      "Transfer subject:": [""],
      "Amount:": [""],
      "Field(s) missing.": [""],
      "Want to try the raw payto://-format?": [""],
      "Missing payto address": [""],
      "Payto does not follow the pattern": [""],
      "Transfer money to account identified by payto:// URI:": [""],
      "payto URI:": [""],
      "payto address": [""],
      Send: [""],
      "Use wire-transfer form?": [""],
      "No credentials found.": [""],
      "Could not create the wire transfer": [""],
      "Transfer creation gave response error": [""],
      "Wire transfer created!": [""],
      "Amount to withdraw:": [""],
      Withdraw: [""],
      "No credentials given.": [""],
      "Could not create withdrawal operation": [""],
      "Withdrawal creation gave response error": [""],
      "Obtain digital cash": [""],
      "Transfer to bank account": [""],
      Date: [""],
      Amount: [""],
      Counterpart: [""],
      Subject: [""],
      "Transfer to Taler Wallet": [""],
      "Use this QR code to withdraw to your mobile wallet:": [""],
      "Click %1$s to open your Taler wallet!": [""],
      "Confirm Withdrawal": [""],
      "Authorize withdrawal by solving challenge": [""],
      "What is": [""],
      "Answer is wrong.": [""],
      Confirm: [""],
      Cancel: [""],
      "A this point, a %1$s bank would ask for an additional authentication proof (PIN/TAN, one time password, ..), instead of a simple calculation.":
        [""],
      "No withdrawal ID found.": [""],
      "Could not confirm the withdrawal": [""],
      "Withdrawal confirmation gave response error": [""],
      "Withdrawal confirmed!": [""],
      "Could not abort the withdrawal.": [""],
      "Withdrawal abortion failed.": [""],
      "Withdrawal aborted!": [""],
      Abort: [""],
      "withdrawal (%1$s) was never (correctly) created at the bank...": [""],
      "Waiting the bank to create the operation...": [""],
      "This withdrawal was aborted!": [""],
      "Welcome to %1$s!": [""],
      "Username or account label '%1$s' not found.  Won't login.": [""],
      "Wrong credentials given.": [""],
      "Account information could not be retrieved.": [""],
      "Welcome, %1$s !": [""],
      "Bank account balance": [""],
      Payments: [""],
      "Latest transactions:": [""],
      "List of public accounts was not found.": [""],
      "List of public accounts could not be retrieved.": [""],
      "History of public accounts": [""],
      "Currently, the bank is not accepting new registrations!": [""],
      "Use only letter and numbers starting with a lower case letter": [""],
      "Password don't match": [""],
      "Please register!": [""],
      "Repeat Password:": [""],
      "Registration failed, please report": [""],
      "That username is already taken": [""],
      "New registration gave response error": [""],
      "Bank menu": [""],
      "Select option1": [""],
      "Select option2": [""],
      days: [""],
      hours: [""],
      minutes: [""],
      seconds: [""],
    },
  },
};

strings["en"] = {
  domain: "messages",
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=(n != 1);",
        lang: "en",
      },
      Logout: [""],
      "Skip to main content": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "Taler logo": [""],
      "Missing username": [""],
      "Missing password": [""],
      "Please login!": [""],
      "Username:": [""],
      "Password:": [""],
      Login: [""],
      Register: [""],
      "Missing IBAN": [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "Missing subject": [""],
      "Missing amount": [""],
      "Amount is not valid": [""],
      "Should be greater than 0": [""],
      "Receiver IBAN:": [""],
      "Transfer subject:": [""],
      "Amount:": [""],
      "Field(s) missing.": [""],
      "Want to try the raw payto://-format?": [""],
      "Missing payto address": [""],
      "Payto does not follow the pattern": [""],
      "Transfer money to account identified by payto:// URI:": [""],
      "payto URI:": [""],
      "payto address": [""],
      Send: [""],
      "Use wire-transfer form?": [""],
      "No credentials found.": [""],
      "Could not create the wire transfer": [""],
      "Transfer creation gave response error": [""],
      "Wire transfer created!": [""],
      "Amount to withdraw:": ["Amount to withdraw"],
      Withdraw: ["Confirm withdrawal"],
      "No credentials given.": [""],
      "Could not create withdrawal operation": [""],
      "Withdrawal creation gave response error": [""],
      "Obtain digital cash": [""],
      "Transfer to bank account": [""],
      Date: [""],
      Amount: [""],
      Counterpart: [""],
      Subject: [""],
      "Transfer to Taler Wallet": ["Top up Taler wallet"],
      "Use this QR code to withdraw to your mobile wallet:": [""],
      "Click %1$s to open your Taler wallet!": [""],
      "Confirm Withdrawal": ["Confirm withdrawal"],
      "Authorize withdrawal by solving challenge": [""],
      "What is": [""],
      "Answer is wrong.": [""],
      Confirm: [""],
      Cancel: [""],
      "A this point, a %1$s bank would ask for an additional authentication proof (PIN/TAN, one time password, ..), instead of a simple calculation.":
        [""],
      "No withdrawal ID found.": [""],
      "Could not confirm the withdrawal": ["Confirm withdrawal"],
      "Withdrawal confirmation gave response error": [""],
      "Withdrawal confirmed!": [""],
      "Could not abort the withdrawal.": ["Close Taler withdrawal"],
      "Withdrawal abortion failed.": [""],
      "Withdrawal aborted!": [""],
      Abort: [""],
      "withdrawal (%1$s) was never (correctly) created at the bank...": [""],
      "Waiting the bank to create the operation...": [""],
      "This withdrawal was aborted!": [""],
      "Welcome to %1$s!": [""],
      "Username or account label '%1$s' not found.  Won't login.": [""],
      "Wrong credentials given.": [""],
      "Account information could not be retrieved.": [""],
      "Welcome, %1$s !": [""],
      "Bank account balance": [""],
      Payments: [""],
      "Latest transactions:": [""],
      "List of public accounts was not found.": [""],
      "List of public accounts could not be retrieved.": [""],
      "History of public accounts": [""],
      "Currently, the bank is not accepting new registrations!": [""],
      "Use only letter and numbers starting with a lower case letter": [""],
      "Password don't match": [""],
      "Please register!": [""],
      "Repeat Password:": [""],
      "Registration failed, please report": [""],
      "That username is already taken": [""],
      "New registration gave response error": [""],
      "Bank menu": [""],
      "Select option1": [""],
      "Select option2": [""],
      days: ["days"],
      hours: ["hours"],
      minutes: ["minutes"],
      seconds: ["seconds"],
    },
  },
};

strings["es"] = {
  domain: "messages",
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=n != 1;",
        lang: "es",
      },
      Logout: ["Cierre de sesión"],
      "Skip to main content": ["Saltar el menú de navegación"],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [
          "Esta parte de la demostración muestra cómo funciona un banco que soporta Taler directamente. Además de usar tu propia cuenta de banco, también podrás ver el historial de transacciones de algunas %1$s.",
        ],
      "Taler logo": ["Logo Taler"],
      "Missing username": ["Falta nombre de usuario"],
      "Missing password": ["Falta contraseña"],
      "Please login!": ["Por favor inicia sesión!"],
      "Username:": ["Nombre de usuario:"],
      "Password:": ["Password:"],
      Login: ["Iniciar sesión"],
      Register: ["Registrarse"],
      "Missing IBAN": ["Falta IBAN"],
      "IBAN should have just uppercased letters and numbers": [
        "IBAN debería tener letras mayúsculas y números",
      ],
      "Missing subject": ["Falta asunto"],
      "Missing amount": ["Falta monto"],
      "Amount is not valid": ["Monto no válido"],
      "Should be greater than 0": ["Debería ser mas grande que 0"],
      "Receiver IBAN:": ["IBAN receptor:"],
      "Transfer subject:": ["Asunto de transferencia:"],
      "Amount:": ["Monto:"],
      "Field(s) missing.": ["Faltan campo(s)."],
      "Want to try the raw payto://-format?": [
        "Quieres probar el formato payto:// ?",
      ],
      "Missing payto address": ["Falta direccion payto"],
      "Payto does not follow the pattern": ["Payto no sigue el patrón"],
      "Transfer money to account identified by payto:// URI:": [
        "Transferir dinero a la cuenta identificada por la URI payto://:",
      ],
      "payto URI:": ["payto URI:"],
      "payto address": ["direccion payto"],
      Send: ["Envíar"],
      "Use wire-transfer form?": [
        "Usar el formulario de transferencia bancaria?",
      ],
      "No credentials found.": ["Se dieron las credenciales incorrectas."],
      "Could not create the wire transfer": [
        "No se pudo create la transferencia bancaria",
      ],
      "Transfer creation gave response error": [
        "La creación de la transferencia dió una respuesta erronea",
      ],
      "Wire transfer created!": ["Transferencia bancaria creada!"],
      "Amount to withdraw:": ["Monto a retirar:"],
      Withdraw: ["Retirar"],
      "No credentials given.": ["Se dieron las credenciales incorrectas."],
      "Could not create withdrawal operation": [
        "No se pude create la operación de retiro",
      ],
      "Withdrawal creation gave response error": [
        "La creación de retiro dió una respuesta errónea",
      ],
      "Obtain digital cash": ["Obtener dinero digital"],
      "Transfer to bank account": ["Transferir a una cuenta bancaria"],
      Date: ["Fecha"],
      Amount: ["Monto"],
      Counterpart: ["Contraparte"],
      Subject: ["Asunto"],
      "Transfer to Taler Wallet": ["Transferir a una cartera Taler"],
      "Use this QR code to withdraw to your mobile wallet:": [
        "Usar el código QR para retirar a tu cartera móvil:",
      ],
      "Click %1$s to open your Taler wallet!": [
        "Click %1$s para abrir una cartera Taler!",
      ],
      "Confirm Withdrawal": ["Confirmar retirada"],
      "Authorize withdrawal by solving challenge": [
        "Autorizar retiro resolviendo una pregunta",
      ],
      "What is": ["Cuanto es"],
      "Answer is wrong.": ["La respuesta es incorrecta."],
      Confirm: ["Confirmar"],
      Cancel: ["Cancelar"],
      "A this point, a %1$s bank would ask for an additional authentication proof (PIN/TAN, one time password, ..), instead of a simple calculation.":
        [
          "En este punto, un banco %1$s preguntaría por una prueba adicional de autenticación (PIN/TAN, password de un solo uso, ....), en vez de un simple cálculo.",
        ],
      "No withdrawal ID found.": ["No ID de retiro encontrado."],
      "Could not confirm the withdrawal": ["No se pudo confirmar la retirada"],
      "Withdrawal confirmation gave response error": [
        "La confirmación de retiro dió una respuesta errónea",
      ],
      "Withdrawal confirmed!": ["El retiro fue confirmado!"],
      "Could not abort the withdrawal.": ["No se pudo cancelar el retiro."],
      "Withdrawal abortion failed.": ["La cancelación del retiro falló."],
      "Withdrawal aborted!": ["Este retiro fue cancelado!"],
      Abort: ["Cancelar"],
      "withdrawal (%1$s) was never (correctly) created at the bank...": [
        "retiro (%1$s) nunca fue (correctamente) generado en el banco...",
      ],
      "Waiting the bank to create the operation...": [
        "Esperando que el banco genere la operación....",
      ],
      "This withdrawal was aborted!": ["Este retiro fue cancelado!"],
      "Welcome to %1$s!": ["Bienvenido a %1$s!"],
      "Username or account label '%1$s' not found.  Won't login.": [
        "Nombre de usuario o etiqueta de cuenta '%1$s' no encontrada. No se iniciará sesión.",
      ],
      "Wrong credentials given.": ["Se dieron las credenciales incorrectas."],
      "Account information could not be retrieved.": [
        "La información de la cuenta no pudo ser accedida.",
      ],
      "Welcome, %1$s !": ["Bienvenido/a, %1$s!"],
      "Bank account balance": ["Balance de cuenta bancaria"],
      Payments: ["Pagos"],
      "Latest transactions:": ["Últimas transacciones:"],
      "List of public accounts was not found.": [
        "La lista de cuentas públicas no fue encontrada.",
      ],
      "List of public accounts could not be retrieved.": [
        "La lista de cuentas públicas no pudo ser accedida.",
      ],
      "History of public accounts": ["Historial de cuentas públicas"],
      "Currently, the bank is not accepting new registrations!": [
        "Actualmente, el banco no está aceptado nuevos registros!",
      ],
      "Use only letter and numbers starting with a lower case letter": [
        "Solo use letras y números comenzando con una letra minúscula",
      ],
      "Password don't match": ["La contraseña no coincide"],
      "Please register!": ["Por favor, registrese!"],
      "Repeat Password:": ["Repita la contraseña:"],
      "Registration failed, please report": [
        "El registro falló, por favor reportelo",
      ],
      "That username is already taken": [
        "El nombre del usuario ya está tomado",
      ],
      "New registration gave response error": [
        "Nuevo registro dió una respuesta errónea",
      ],
      "Bank menu": ["Menu del banco"],
      "Select option1": ["Seleccione opción 1"],
      "Select option2": ["Seleccione opción 2"],
      days: ["días"],
      hours: ["horas"],
      minutes: ["minutos"],
      seconds: ["segundos"],
    },
  },
};

strings["it"] = {
  domain: "messages",
  locale_data: {
    messages: {
      "": {
        domain: "messages",
        plural_forms: "nplurals=2; plural=(n != 1);",
        lang: "it",
      },
      Logout: [""],
      "Skip to main content": [""],
      "This part of the demo shows how a bank that supports Taler directly would work. In addition to using your own bank account, you can also see the transaction history of some %1$s.":
        [""],
      "Taler logo": [""],
      "Missing username": [""],
      "Missing password": [""],
      "Please login!": ["Accedi!"],
      "Username:": [""],
      "Password:": [""],
      Login: ["Accedi"],
      Register: ["Registrati"],
      "Missing IBAN": [""],
      "IBAN should have just uppercased letters and numbers": [""],
      "Missing subject": [""],
      "Missing amount": [""],
      "Amount is not valid": [""],
      "Should be greater than 0": [""],
      "Receiver IBAN:": [""],
      "Transfer subject:": [""],
      "Amount:": ["Somma"],
      "Field(s) missing.": [""],
      "Want to try the raw payto://-format?": [
        "Prova il trasferimento tramite il formato Payto!",
      ],
      "Missing payto address": ["indirizzo Payto"],
      "Payto does not follow the pattern": [""],
      "Transfer money to account identified by payto:// URI:": [
        "Trasferisci fondi a un altro conto di questa banca:",
      ],
      "payto URI:": [""],
      "payto address": ["indirizzo Payto"],
      Send: [""],
      "Use wire-transfer form?": ["Chiudi il bonifico"],
      "No credentials found.": ["Credenziali invalide."],
      "Could not create the wire transfer": [""],
      "Transfer creation gave response error": [""],
      "Wire transfer created!": ["Bonifico"],
      "Amount to withdraw:": ["Somma da ritirare"],
      Withdraw: ["Conferma il ritiro"],
      "No credentials given.": ["Credenziali invalide."],
      "Could not create withdrawal operation": [""],
      "Withdrawal creation gave response error": [""],
      "Obtain digital cash": [""],
      "Transfer to bank account": [
        "Trasferisci fondi a un altro conto di questa banca:",
      ],
      Date: [""],
      Amount: ["Somma"],
      Counterpart: ["Controparte"],
      Subject: ["Causale"],
      "Transfer to Taler Wallet": ["Ritira contante nel portafoglio Taler"],
      "Use this QR code to withdraw to your mobile wallet:": [
        "Usa questo codice QR per ritirare contante nel tuo wallet:",
      ],
      "Click %1$s to open your Taler wallet!": [""],
      "Confirm Withdrawal": ["Conferma il ritiro"],
      "Authorize withdrawal by solving challenge": [""],
      "What is": [""],
      "Answer is wrong.": [""],
      Confirm: ["Conferma"],
      Cancel: [""],
      "A this point, a %1$s bank would ask for an additional authentication proof (PIN/TAN, one time password, ..), instead of a simple calculation.":
        [""],
      "No withdrawal ID found.": [""],
      "Could not confirm the withdrawal": ["Conferma il ritiro"],
      "Withdrawal confirmation gave response error": [""],
      "Withdrawal confirmed!": ["Questo ritiro è stato annullato!"],
      "Could not abort the withdrawal.": ["Chiudi il ritiro Taler"],
      "Withdrawal abortion failed.": ["Questo ritiro è stato annullato!"],
      "Withdrawal aborted!": ["Questo ritiro è stato annullato!"],
      Abort: ["Annulla"],
      "withdrawal (%1$s) was never (correctly) created at the bank...": [""],
      "Waiting the bank to create the operation...": [
        "La banca sta creando l'operazione...",
      ],
      "This withdrawal was aborted!": ["Questo ritiro è stato annullato!"],
      "Welcome to %1$s!": [""],
      "Username or account label '%1$s' not found.  Won't login.": [
        "L'utente '%1$s' non esiste.  Login impossibile",
      ],
      "Wrong credentials given.": ["Credenziali invalide."],
      "Account information could not be retrieved.": [
        "Impossibile ricevere le informazioni relative al conto.",
      ],
      "Welcome, %1$s !": [""],
      "Bank account balance": ["Bilancio:"],
      Payments: [""],
      "Latest transactions:": ["Ultime transazioni:"],
      "List of public accounts was not found.": [
        "Lista conti pubblici non trovata.",
      ],
      "List of public accounts could not be retrieved.": [
        "Lista conti pubblici non pervenuta.",
      ],
      "History of public accounts": ["Storico dei conti pubblici"],
      "Currently, the bank is not accepting new registrations!": [""],
      "Use only letter and numbers starting with a lower case letter": [""],
      "Password don't match": [""],
      "Please register!": ["Accedi!"],
      "Repeat Password:": [""],
      "Registration failed, please report": ["Registrazione"],
      "That username is already taken": [""],
      "New registration gave response error": [""],
      "Bank menu": [""],
      "Select option1": [""],
      "Select option2": [""],
      days: [""],
      hours: [""],
      minutes: [""],
      seconds: [""],
    },
  },
};
