import { LibtoolVersion } from "@gnu-taler/taler-util";
import { ErrorType, HttpError, HttpResponseServerError, RequestError, useApiContext } from "@gnu-taler/web-util/browser";
import { useEffect, useState } from "preact/hooks";
import { getInitialBackendBaseURL } from "./backend.js";

/**
 * Protocol version spoken with the bank.
 *
 * Uses libtool's current:revision:age versioning.
 */
export const BANK_INTEGRATION_PROTOCOL_VERSION = "0:0:0";

async function getConfigState(
  request: ReturnType<typeof useApiContext>["request"],
): Promise<SandboxBackend.Config> {
  const url = getInitialBackendBaseURL();
  const result = await request<SandboxBackend.Config>(url, `config`);
  return result.data;
}

export type ConfigResult = undefined
  | { type: "ok", result: Required<SandboxBackend.Config> }
  | { type: "wrong", result: SandboxBackend.Config }
  | { type: "error", result: HttpError<SandboxBackend.SandboxError> }

export function useConfigState(): ConfigResult {
  const [checked, setChecked] = useState<ConfigResult>()
  const { request } = useApiContext();

  useEffect(() => {
    getConfigState(request)
      .then((result) => {
        const r = LibtoolVersion.compare(BANK_INTEGRATION_PROTOCOL_VERSION, result.version)
        if (r?.compatible) {
          const complete: Required<SandboxBackend.Config> = {
            currency_fraction_digits: result.currency_fraction_digits ?? 2,
            currency_fraction_limit: result.currency_fraction_limit ?? 2,
            fiat_currency: "",
            have_cashout: result.have_cashout ?? false,
            name: result.name,
            version: result.version,
          }
          setChecked({ type: "ok", result: complete });
        } else {
          setChecked({ type: "wrong", result })
        }
      })
      .catch((error: unknown) => {
        if (error instanceof RequestError) {
          const result = error.cause
          setChecked({ type: "error", result });
        }
      });
  }, []);

  return checked;
}


