import { notifyInfo, useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { Cashouts } from "../../components/Cashouts/index.js";
import { ShowCashoutDetails } from "../business/Home.js";
import { handleNotOkResult } from "../HomePage.js";
import { ShowAccountDetails } from "../ShowAccountDetails.js";
import { UpdateAccountPassword } from "../UpdateAccountPassword.js";
import { AdminAccount } from "./Account.js";
import { AccountList } from "./AccountList.js";
import { CreateNewAccount } from "./CreateNewAccount.js";
import { RemoveAccount } from "./RemoveAccount.js";
import { Transactions } from "../../components/Transactions/index.js";

/**
 * Query account information and show QR code if there is pending withdrawal
 */
interface Props {
  onRegister: () => void;
}
export type AccountAction = "show-details" |
  "show-cashout" |
  "update-password" |
  "remove-account" |
  "show-cashouts-details";

export function AdminHome({ onRegister }: Props): VNode {
  const [action, setAction] = useState<{
    type: AccountAction,
    account: string
  } | undefined>()

  const [createAccount, setCreateAccount] = useState(false);

  const { i18n } = useTranslationContext();

  if (action) {
    switch (action.type) {
      case "show-cashouts-details": return <ShowCashoutDetails
        id={action.account}
        onLoadNotOk={handleNotOkResult(i18n)}
        onCancel={() => {
          setAction(undefined);
        }}
      />
      case "show-cashout": return (
        <div>
          <div>
            <h1 class="nav welcome-text">
              <i18n.Translate>Cashout for account {action.account}</i18n.Translate>
            </h1>
          </div>
          <Cashouts
            account={action.account}
            onSelected={(id) => {
              setAction({
                type: "show-cashouts-details",
                account: action.account
              });
            }}
          />
          <p>
            <input
              class="pure-button"
              type="submit"
              value={i18n.str`Close`}
              onClick={async (e) => {
                e.preventDefault();
                setAction(undefined);
              }}
            />
          </p>
        </div>
      )
      case "update-password": return <UpdateAccountPassword
        account={action.account}
        onLoadNotOk={handleNotOkResult(i18n)}
        onUpdateSuccess={() => {
          notifyInfo(i18n.str`Password changed`);
          setAction(undefined);
        }}
        onCancel={() => {
          setAction(undefined);
        }}
      />
      case "remove-account": return <RemoveAccount
        account={action.account}
        onLoadNotOk={handleNotOkResult(i18n)}
        onUpdateSuccess={() => {
          notifyInfo(i18n.str`Account removed`);
          setAction(undefined);
        }}
        onCancel={() => {
          setAction(undefined);
        }}
      />
      case "show-details": return <ShowAccountDetails
        account={action.account}
        onLoadNotOk={handleNotOkResult(i18n)}
        onChangePassword={() => {
          setAction({
            type: "update-password",
            account: action.account,
          })
        }}
        onUpdateSuccess={() => {
          notifyInfo(i18n.str`Account updated`);
          setAction(undefined);
        }}
        onClear={() => {
          setAction(undefined);
        }}
      />
    }
  }

  if (createAccount) {
    return (
      <CreateNewAccount
        onCancel={() => setCreateAccount(false)}
        onCreateSuccess={(password) => {
          notifyInfo(
            i18n.str`Account created with password "${password}". The user must change the password on the next login.`,
          );
          setCreateAccount(false);
        }}
      />
    );
  }

  return (
    <Fragment>

      <AccountList
        onCreateAccount={() => {
          setCreateAccount(true);
        }}
        account={undefined}
        onAction={(type, account) => setAction({ account, type })}
        
      />

      <AdminAccount onRegister={onRegister} />

      <Transactions account="admin"/>
    </Fragment>
  );
}