/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  AmountString,
  Codec,
  buildCodecForObject,
  codecForAmountString,
  codecForBoolean,
  codecForNumber,
  codecForString,
  codecOptional,
} from "@gnu-taler/taler-util";
import { buildStorageKey, useLocalStorage } from "@gnu-taler/web-util/browser";

interface Settings {
  currentWithdrawalOperationId: string | undefined;
  showWithdrawalSuccess: boolean;
  showDemoDescription: boolean;
  showInstallWallet: boolean;
  maxWithdrawalAmount: number;
  fastWithdrawal: boolean;
  showDebugInfo: boolean;
}

export const codecForSettings = (): Codec<Settings> =>
  buildCodecForObject<Settings>()
    .property("currentWithdrawalOperationId", codecOptional(codecForString()))
    .property("showWithdrawalSuccess", (codecForBoolean()))
    .property("showDemoDescription", (codecForBoolean()))
    .property("showInstallWallet", (codecForBoolean()))
    .property("fastWithdrawal", (codecForBoolean()))
    .property("showDebugInfo", (codecForBoolean()))
    .property("maxWithdrawalAmount", codecForNumber())
    .build("Settings");

const defaultSettings: Settings = {
  currentWithdrawalOperationId: undefined,
  showWithdrawalSuccess: true,
  showDemoDescription: true,
  showInstallWallet: true,
  maxWithdrawalAmount: 25,
  fastWithdrawal: false,
  showDebugInfo: false,
};

const DEMOBANK_SETTINGS_KEY = buildStorageKey(
  "demobank-settings",
  codecForSettings(),
);

export function useSettings(): [
  Readonly<Settings>,
  <T extends keyof Settings>(key: T, value: Settings[T]) => void,
] {
  const { value, update } = useLocalStorage(
    DEMOBANK_SETTINGS_KEY,
    defaultSettings,
  );

  function updateField<T extends keyof Settings>(k: T, v: Settings[T]) {
    const newValue = { ...value, [k]: v };
    update(newValue);
  }
  return [value, updateField];
}
