/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { Fragment, h, VNode } from "preact";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { State } from "./index.js";
import { format, isToday } from "date-fns";
import { Amounts } from "@gnu-taler/taler-util";
import { useEffect, useRef } from "preact/hooks";
import { RenderAmount } from "../../pages/PaytoWireTransferForm.js";

export function LoadingUriView({ error }: State.LoadingUriError): VNode {
  const { i18n } = useTranslationContext();

  return (
    <div>
      <i18n.Translate>Could not load</i18n.Translate>
    </div>
  );
}

export function ReadyView({ transactions, onNext, onPrev }: State.Ready): VNode {
  const { i18n } = useTranslationContext();
  if (!transactions.length) return <div />
  const txByDate = transactions.reduce((prev, cur) => {
    const d = cur.when.t_ms === "never"
      ? ""
      : format(cur.when.t_ms, "dd/MM/yyyy")
    if (!prev[d]) {
      prev[d] = []
    }
    prev[d].push(cur)
    return prev
  }, {} as Record<string, typeof transactions>)
  return (
    <div class="px-4 mt-4">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900"><i18n.Translate>Latest transactions</i18n.Translate></h1>
        </div>
      </div>
      <div class="-mx-4 mt-5 ring-1 ring-gray-300 sm:mx-0 rounded-lg min-w-fit bg-white">
        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 ">{i18n.str`Date`}</th>
              <th scope="col" class="hidden sm:table-cell pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 ">{i18n.str`Amount`}</th>
              <th scope="col" class="hidden sm:table-cell pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 ">{i18n.str`Counterpart`}</th>
              <th scope="col" class="pl-2 py-3.5 text-left text-sm font-semibold text-gray-900 ">{i18n.str`Subject`}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(txByDate).map(([date, txs], idx) => {
              return <Fragment>
                <tr class="border-t border-gray-200">
                  <th colSpan={4} scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    {date}
                  </th>
                </tr>
                {txs.map(item => {
                  const time = item.when.t_ms === "never" ? "" : format(item.when.t_ms, "HH:mm:ss")
                  const amount = <Fragment>
                    { }
                  </Fragment>
                  return (<tr key={idx}>
                    <td class="relative py-2 pl-2 pr-2 text-sm ">
                      <div class="font-medium text-gray-900">{time}</div>
                      <dl class="font-normal sm:hidden">
                        <dt class="sr-only sm:hidden"><i18n.Translate>Amount</i18n.Translate></dt>
                        <dd class="mt-1 truncate text-gray-700">
                          {item.negative ? i18n.str`sent` : i18n.str`received`} {item.amount ? (
                            <RenderAmount value={item.amount} />
                          ) : (
                            <span style={{ color: "grey" }}>&lt;{i18n.str`invalid value`}&gt;</span>
                          )}</dd>

                        <dt class="sr-only sm:hidden"><i18n.Translate>Counterpart</i18n.Translate></dt>
                        <dd class="mt-1 truncate text-gray-500 sm:hidden">
                          {item.negative ? i18n.str`to` : i18n.str`from`} {item.counterpart}
                        </dd>
                      </dl>
                    </td>
                    <td data-negative={item.negative ? "true" : "false"}
                      class="hidden sm:table-cell px-3 py-3.5 text-sm text-gray-500 data-[negative=false]:text-green-600 data-[negative=true]:text-red-600">
                      {item.amount ? (<RenderAmount value={item.amount} negative={item.negative} />
                      ) : (
                        <span style={{ color: "grey" }}>&lt;{i18n.str`invalid value`}&gt;</span>
                      )}
                    </td>
                    <td class="hidden sm:table-cell px-3 py-3.5 text-sm text-gray-500">{item.counterpart}</td>
                    <td class="px-3 py-3.5 text-sm text-gray-500 break-all min-w-md">{item.subject}</td>
                  </tr>)
                })}
              </Fragment>

            })}
          </tbody>

        </table>

        <nav class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-lg" aria-label="Pagination">
          <div class="flex flex-1 justify-between sm:justify-end">
            <button
              class="relative disabled:bg-gray-100 disabled:text-gray-500 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              disabled={!onPrev}
              onClick={onPrev}
            >
              <i18n.Translate>First page</i18n.Translate>
            </button>
            <button
              class="relative disabled:bg-gray-100 disabled:text-gray-500 ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
              disabled={!onNext}
              onClick={onNext}
            >
              <i18n.Translate>Next</i18n.Translate>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}
