import { Amounts } from "@gnu-taler/taler-util";
import { PaytoWireTransferForm } from "../PaytoWireTransferForm.js";
import { handleNotOkResult } from "../HomePage.js";
import { useAccountDetails } from "../../hooks/access.js";
import { useBackendContext } from "../../context/backend.js";
import { notifyInfo, useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";

export function AdminAccount({ onRegister }: { onRegister: () => void }): VNode {
  const { i18n } = useTranslationContext();
  const r = useBackendContext();
  const account = r.state.status !== "loggedOut" ? r.state.username : "admin";
  const result = useAccountDetails(account);

  if (!result.ok) {
    return handleNotOkResult(i18n)(result);
  }
  const { data } = result;

  const balance = Amounts.parseOrThrow(data.balance.amount);
  const balanceIsDebit = result.data.balance.credit_debit_indicator == "debit";
  
  const debitThreshold = Amounts.parseOrThrow(result.data.debit_threshold);
  const limit = balanceIsDebit
    ? Amounts.sub(debitThreshold, balance).amount
    : Amounts.add(balance, debitThreshold).amount;
  if (!balance) return <Fragment />;
  return (
    <PaytoWireTransferForm
      title={i18n.str`Make a wire transfer`}
      limit={limit}
      onSuccess={() => {
        notifyInfo(i18n.str`Wire transfer created!`);
      }}
      onCancel={undefined}
    />
  );
}
