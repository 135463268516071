/*
/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { HttpError, useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { Attention } from "./Attention.js";
import { TranslatedString } from "@gnu-taler/taler-util";

export function ErrorLoading({ error }: { error: HttpError<SandboxBackend.SandboxError> }): VNode {
  const { i18n } = useTranslationContext()
  return (<Attention type="danger" title={error.message as TranslatedString}>
    <p class="text-sm font-medium text-red-800">Got status "{error.info.status}" on {error.info.url}</p>
  </Attention>
  );
}
