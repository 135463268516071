/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  LibtoolVersion,
  getGlobalLogLevel,
  setGlobalLogLevelFromString,
} from "@gnu-taler/taler-util";
import { TranslationProvider, useApiContext } from "@gnu-taler/web-util/browser";
import { ComponentChildren, Fragment, FunctionalComponent, VNode, h } from "preact";
import { SWRConfig } from "swr";
import { BackendStateProvider, useBackendContext } from "../context/backend.js";
import { strings } from "../i18n/strings.js";
import { Routing } from "./Routing.js";
import { useEffect, useState } from "preact/hooks";
import { Loading } from "./Loading.js";
import { getInitialBackendBaseURL } from "../hooks/backend.js";
import { BANK_INTEGRATION_PROTOCOL_VERSION, useConfigState } from "../hooks/config.js";
import { ErrorLoading } from "./ErrorLoading.js";
import { BankFrame } from "../pages/BankFrame.js";
import { ConfigStateProvider } from "../context/config.js";
const WITH_LOCAL_STORAGE_CACHE = false;

/**
 * FIXME:
 *
 * - INPUT elements have their 'required' attribute ignored.
 *
 * - the page needs a "home" button that either redirects to
 *   the profile page (when the user is logged in), or to
 *   the very initial home page.
 *
 * - histories 'pages' are grouped in UL elements that cause
 *   the rendering to visually separate each UL.  History elements
 *   should instead line up without any separation caused by
 *   a implementation detail.
 *
 * - Many strings need to be i18n-wrapped.
 */

const App: FunctionalComponent = () => {
  return (
    <TranslationProvider source={strings}>
      <BackendStateProvider>
        <VersionCheck>
          <SWRConfig
            value={{
              provider: WITH_LOCAL_STORAGE_CACHE
                ? localStorageProvider
                : undefined,
            }}
          >
            <Routing />
          </SWRConfig>
        </VersionCheck>
      </BackendStateProvider>
    </TranslationProvider >
  );
};
(window as any).setGlobalLogLevelFromString = setGlobalLogLevelFromString;
(window as any).getGlobalLevel = getGlobalLogLevel;

function VersionCheck({ children }: { children: ComponentChildren }): VNode {
  const checked = useConfigState()

  if (checked === undefined) {
    return <Loading />
  }
  if (checked.type === "wrong") {
    return <BankFrame>
      the bank backend is not supported. supported version "{BANK_INTEGRATION_PROTOCOL_VERSION}", server version "{checked}"
    </BankFrame>
  }
  if (checked.type === "ok") {
    return <ConfigStateProvider value={checked.result}>{children}</ConfigStateProvider>
  }

  return <BankFrame>
    <ErrorLoading error={checked.result} />
  </BankFrame>
}

function localStorageProvider(): Map<unknown, unknown> {
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
  });
  return map;
}

export default App;
