/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { AmountJson } from "@gnu-taler/taler-util";
import { notifyInfo, useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { PaytoWireTransferForm } from "./PaytoWireTransferForm.js";
import { WalletWithdrawForm } from "./WalletWithdrawForm.js";
import { useSettings } from "../hooks/settings.js";

/**
 * Let the user choose a payment option,
 * then specify the details trigger the action.
 */
export function PaymentOptions({ limit, goToConfirmOperation }: { limit: AmountJson, goToConfirmOperation: (id: string) => void }): VNode {
  const { i18n } = useTranslationContext();
  const [settings] = useSettings();

  const [tab, setTab] = useState<"charge-wallet" | "wire-transfer" | undefined>();

  return (
    <div class="mt-2">

      <fieldset>
        <legend class="px-4 text-base font-semibold leading-6 text-gray-900">
          <i18n.Translate>Send money to</i18n.Translate>
        </legend>

        <div class="px-4 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {/* <!-- Active: "border-indigo-600 ring-2 ring-indigo-600", Not Active: "border-gray-300" --> */}
          <label class={"relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none" + (tab === "charge-wallet" ? "border-indigo-600 ring-2 ring-indigo-600" : "border-gray-300")}>
            <input type="radio" name="project-type" value="Newsletter" class="sr-only" aria-labelledby="project-type-0-label" aria-describedby="project-type-0-description-0 project-type-0-description-1" onClick={() => {
              setTab("charge-wallet")
            }} />
            <span class="flex flex-1">
              <div class="text-4xl mr-4 my-auto">&#x1F4B5;</div>
              <span class="flex flex-col">
                <span id="project-type-0-label" class="block text-sm font-medium text-gray-900">
                  <i18n.Translate>a <b>Taler</b> wallet</i18n.Translate>
                </span>
                <span id="project-type-0-description-0" class="mt-1 flex items-center text-sm text-gray-500">
                  <i18n.Translate>Withdraw digital money into your mobile wallet or browser extension</i18n.Translate>
                </span>
                {!!settings.currentWithdrawalOperationId &&
                  <span class="inline-flex items-center gap-x-1.5 w-fit rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                    <svg class="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                      <circle cx="3" cy="3" r="3" />
                    </svg>
                    <i18n.Translate>operation ready</i18n.Translate>
                  </span>
                }
              </span>
            </span>
            <svg class="h-5 w-5 text-indigo-600" style={{ visibility: tab === "charge-wallet" ? "visible" : "hidden" }} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
          </label>


          <label class={"relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none" + (tab === "wire-transfer" ? "border-indigo-600 ring-2 ring-indigo-600" : "border-gray-300")}>
            <input type="radio" name="project-type" value="Existing Customers" class="sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-1-description-0 project-type-1-description-1" onClick={() => {
              setTab("wire-transfer")
            }} />
            <span class="flex flex-1">
              <div class="text-4xl mr-4 my-auto">&#x2194;</div>
              <span class="flex flex-col">
                <span id="project-type-1-label" class="block text-sm font-medium text-gray-900">
                  <i18n.Translate>another bank account</i18n.Translate>
                </span>
                <span id="project-type-1-description-0" class="mt-1 flex items-center text-sm text-gray-500">
                  <i18n.Translate>Make a wire transfer to an account which you know the bank account number</i18n.Translate>
                </span>
              </span>
            </span>
            <svg class="h-5 w-5 text-indigo-600" style={{ visibility: tab === "wire-transfer" ? "visible" : "hidden" }} viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
          </label>
        </div>
        {tab === "charge-wallet" && (
          <WalletWithdrawForm
            focus
            limit={limit}
            goToConfirmOperation={goToConfirmOperation}
            onCancel={() => {
              setTab(undefined)
            }}
          />
        )}
        {tab === "wire-transfer" && (
          <PaytoWireTransferForm
            focus
            title={i18n.str`Transfer details`}
            limit={limit}
            onSuccess={() => {
              notifyInfo(i18n.str`Wire transfer created!`);
              setTab(undefined)
            }}
            onCancel={() => {
              setTab(undefined)
            }}
          />
        )}

      </fieldset>
    </div>
  )
}
