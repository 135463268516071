/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { Attention } from "../../components/Attention.js";
import { Transactions } from "../../components/Transactions/index.js";
import { useBusinessAccountDetails } from "../../hooks/circuit.js";
import { useSettings } from "../../hooks/settings.js";
import { PaymentOptions } from "../PaymentOptions.js";
import { State } from "./index.js";

export function InvalidIbanView({ error }: State.InvalidIban) {
  return (
    <div>Payto from server is not valid &quot;{error.data.payto_uri}&quot;</div>
  );
}

const IS_PUBLIC_ACCOUNT_ENABLED = false

function ShowDemoInfo(): VNode {
  const { i18n } = useTranslationContext();
  const [settings, updateSettings] = useSettings();
  if (!settings.showDemoDescription) return <Fragment />
  return <Attention title={i18n.str`This is a demo bank`} onClose={() => {
    updateSettings("showDemoDescription", false);
  }}>
    {IS_PUBLIC_ACCOUNT_ENABLED ? (
      <i18n.Translate>
        This part of the demo shows how a bank that supports Taler
        directly would work. In addition to using your own bank
        account, you can also see the transaction history of some{" "}
        <a href="/public-accounts">Public Accounts</a>.
      </i18n.Translate>
    ) : (
      <i18n.Translate>
        This part of the demo shows how a bank that supports Taler
        directly would work.
      </i18n.Translate>
    )}
  </Attention>
}

export function ReadyView({ account, limit, goToBusinessAccount, goToConfirmOperation }: State.Ready): VNode<{}> {
  const { i18n } = useTranslationContext();

  return <Fragment>
    <MaybeBusinessButton account={account} onClick={goToBusinessAccount} />

    <ShowDemoInfo />

    <PaymentOptions limit={limit} goToConfirmOperation={goToConfirmOperation} />
    <Transactions account={account} />
  </Fragment>;
}

function MaybeBusinessButton({
  account,
  onClick,
}: {
  account: string;
  onClick: () => void;
}): VNode {
  const { i18n } = useTranslationContext();
  const result = useBusinessAccountDetails(account);
  if (!result.ok) return <Fragment />;
  return (
    <div class="w-full flex justify-end">
      <button
        class="disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
      >
        <i18n.Translate>Business Profile</i18n.Translate>
      </button>
    </div>
  );
}
