/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  HttpResponse,
  HttpResponseOk,
  HttpResponsePaginated,
  RequestError,
} from "@gnu-taler/web-util/browser";
import { useEffect, useState } from "preact/hooks";
import { useBackendContext } from "../context/backend.js";
import { MAX_RESULT_SIZE, PAGE_SIZE } from "../utils.js";
import {
  useAuthenticatedBackend,
  useMatchMutate,
  usePublicBackend,
} from "./backend.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import _useSWR, { SWRHook } from "swr";
import { Amounts } from "@gnu-taler/taler-util";
const useSWR = _useSWR as unknown as SWRHook;

export function useAccessAPI(): AccessAPI {
  const mutateAll = useMatchMutate();
  const { request } = useAuthenticatedBackend();
  const { state } = useBackendContext();
  if (state.status === "loggedOut") {
    throw Error("access-api can't be used when the user is not logged In");
  }
  const account = state.username;

  const createWithdrawal = async (
    data: SandboxBackend.CoreBank.BankAccountCreateWithdrawalRequest,
  ): Promise<
    HttpResponseOk<SandboxBackend.CoreBank.BankAccountCreateWithdrawalResponse>
  > => {
    const res =
      await request<SandboxBackend.CoreBank.BankAccountCreateWithdrawalResponse>(
        `accounts/${account}/withdrawals`,
        {
          method: "POST",
          data,
          contentType: "json",
        },
      );
    return res;
  };
  const createTransaction = async (
    data: SandboxBackend.CoreBank.CreateBankAccountTransactionCreate,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(
      `accounts/${account}/transactions`,
      {
        method: "POST",
        data,
        contentType: "json",
      },
    );
    await mutateAll(/.*accounts\/.*/);
    return res;
  };
  const deleteAccount = async (): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`accounts/${account}`, {
      method: "DELETE",
      contentType: "json",
    });
    await mutateAll(/.*accounts\/.*/);
    return res;
  };

  return {
    createWithdrawal,
    createTransaction,
    deleteAccount,
  };
}

export function useAccessAnonAPI(): AccessAnonAPI {
  const mutateAll = useMatchMutate();
  const { request } = useAuthenticatedBackend();

  const abortWithdrawal = async (id: string): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`withdrawals/${id}/abort`, {
      method: "POST",
      contentType: "json",
    });
    await mutateAll(/.*withdrawals\/.*/);
    return res;
  };
  const confirmWithdrawal = async (
    id: string,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`withdrawals/${id}/confirm`, {
      method: "POST",
      contentType: "json",
    });
    await mutateAll(/.*withdrawals\/.*/);
    return res;
  };

  return {
    abortWithdrawal,
    confirmWithdrawal,
  };
}

export function useTestingAPI(): TestingAPI {
  const mutateAll = useMatchMutate();
  const { request: noAuthRequest } = usePublicBackend();
  const register = async (
    data: SandboxBackend.CoreBank.RegisterAccountRequest,
  ): Promise<HttpResponseOk<void>> => {
    // FIXME: This API is deprecated.  The normal account registration API should be used instead.
    const res = await noAuthRequest<void>(`accounts`, {
      method: "POST",
      data,
      contentType: "json",
    });
    await mutateAll(/.*accounts\/.*/);
    return res;
  };

  return { register };
}

export interface TestingAPI {
  register: (
    data: SandboxBackend.CoreBank.RegisterAccountRequest,
  ) => Promise<HttpResponseOk<void>>;
}

export interface AccessAPI {
  createWithdrawal: (
    data: SandboxBackend.CoreBank.BankAccountCreateWithdrawalRequest,
  ) => Promise<
    HttpResponseOk<SandboxBackend.CoreBank.BankAccountCreateWithdrawalResponse>
  >;
  createTransaction: (
    data: SandboxBackend.CoreBank.CreateBankAccountTransactionCreate,
  ) => Promise<HttpResponseOk<void>>;
  deleteAccount: () => Promise<HttpResponseOk<void>>;
}
export interface AccessAnonAPI {
  abortWithdrawal: (wid: string) => Promise<HttpResponseOk<void>>;
  confirmWithdrawal: (wid: string) => Promise<HttpResponseOk<void>>;
}

export interface InstanceTemplateFilter {
  //FIXME: add filter to the template list
  position?: string;
}

export function useAccountDetails(
  account: string,
): HttpResponse<
  SandboxBackend.CoreBank.AccountData,
  SandboxBackend.SandboxError
> {
  const { fetcher } = useAuthenticatedBackend();

  const { data, error } = useSWR<
    HttpResponseOk<SandboxBackend.CoreBank.AccountData>,
    RequestError<SandboxBackend.SandboxError>
  >([`accounts/${account}`], fetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    errorRetryCount: 0,
    errorRetryInterval: 1,
    shouldRetryOnError: false,
    keepPreviousData: true,
  });

  if (data) {
    return data;
  }
  if (error) return error.cause;
  return { loading: true };
}

// FIXME: should poll
export function useWithdrawalDetails(
  wid: string,
): HttpResponse<
  SandboxBackend.CoreBank.BankAccountGetWithdrawalResponse,
  SandboxBackend.SandboxError
> {
  const { fetcher } = useAuthenticatedBackend();

  const { data, error } = useSWR<
    HttpResponseOk<SandboxBackend.CoreBank.BankAccountGetWithdrawalResponse>,
    RequestError<SandboxBackend.SandboxError>
  >([`withdrawals/${wid}`], fetcher, {
    refreshInterval: 1000,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    errorRetryCount: 0,
    errorRetryInterval: 1,
    shouldRetryOnError: false,
    keepPreviousData: true,
  });

  // if (isValidating) return { loading: true, data: data?.data };
  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}

export function useTransactionDetails(
  account: string,
  tid: string,
): HttpResponse<
  SandboxBackend.CoreBank.BankAccountTransactionInfo,
  SandboxBackend.SandboxError
> {
  const { paginatedFetcher } = useAuthenticatedBackend();

  const { data, error } = useSWR<
    HttpResponseOk<SandboxBackend.CoreBank.BankAccountTransactionInfo>,
    RequestError<SandboxBackend.SandboxError>
  >([`accounts/${account}/transactions/${tid}`], paginatedFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    errorRetryCount: 0,
    errorRetryInterval: 1,
    shouldRetryOnError: false,
    keepPreviousData: true,
  });

  // if (isValidating) return { loading: true, data: data?.data };
  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}

interface PaginationFilter {
  // page: number;
}

export function usePublicAccounts(
  args?: PaginationFilter,
): HttpResponsePaginated<
  SandboxBackend.CoreBank.PublicAccountsResponse,
  SandboxBackend.SandboxError
> {
  const { paginatedFetcher } = usePublicBackend();

  const [page, setPage] = useState(1);

  const {
    data: afterData,
    error: afterError,
    isValidating: loadingAfter,
  } = useSWR<
    HttpResponseOk<SandboxBackend.CoreBank.PublicAccountsResponse>,
    RequestError<SandboxBackend.SandboxError>
  >([`public-accounts`, page, PAGE_SIZE], paginatedFetcher);

  const [lastAfter, setLastAfter] = useState<
    HttpResponse<
      SandboxBackend.CoreBank.PublicAccountsResponse,
      SandboxBackend.SandboxError
    >
  >({ loading: true });

  useEffect(() => {
    if (afterData) setLastAfter(afterData);
  }, [afterData]);

  if (afterError) return afterError.cause;

  // if the query returns less that we ask, then we have reach the end or beginning
  const isReachingEnd =
    afterData && afterData.data.public_accounts.length < PAGE_SIZE;
  const isReachingStart = false;

  const pagination = {
    isReachingEnd,
    isReachingStart,
    loadMore: () => {
      if (!afterData || isReachingEnd) return;
      if (afterData.data.public_accounts.length < MAX_RESULT_SIZE) {
        setPage(page + 1);
      }
    },
    loadMorePrev: () => {
      null;
    },
  };

  const public_accounts = !afterData
    ? []
    : (afterData || lastAfter).data.public_accounts;
  if (loadingAfter) return { loading: true, data: { public_accounts } };
  if (afterData) {
    return { ok: true, data: { public_accounts }, ...pagination };
  }
  return { loading: true };
}

/**
 * FIXME: mutate result when balance change (transaction )
 * @param account
 * @param args
 * @returns
 */
export function useTransactions(
  account: string,
  args?: PaginationFilter,
): HttpResponsePaginated<
  SandboxBackend.CoreBank.BankAccountTransactionsResponse,
  SandboxBackend.SandboxError
> {
  const { paginatedFetcher } = useAuthenticatedBackend();

  const [start, setStart] = useState<string>();

  const {
    data: afterData,
    error: afterError,
    isValidating: loadingAfter,
  } = useSWR<
    HttpResponseOk<SandboxBackend.CoreBank.BankAccountTransactionsResponse>,
    RequestError<SandboxBackend.SandboxError>
  >(
    [`accounts/${account}/transactions`, start, PAGE_SIZE],
    paginatedFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    // revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }
  );

  const [lastAfter, setLastAfter] = useState<
    HttpResponse<
      SandboxBackend.CoreBank.BankAccountTransactionsResponse,
      SandboxBackend.SandboxError
    >
  >({ loading: true });

  useEffect(() => {
    if (afterData) setLastAfter(afterData);
  }, [afterData]);

  if (afterError) {
    return afterError.cause;
  }

  // if the query returns less that we ask, then we have reach the end or beginning
  const isReachingEnd =
    afterData && afterData.data.transactions.length < PAGE_SIZE;
  const isReachingStart = start == undefined;

  const pagination = {
    isReachingEnd,
    isReachingStart,
    loadMore: () => {
      if (!afterData || isReachingEnd) return;
      // if (afterData.data.transactions.length < MAX_RESULT_SIZE) {
      const l = afterData.data.transactions[afterData.data.transactions.length-1]
      setStart(String(l.row_id));
      // }
    },
    loadMorePrev: () => {
      if (!afterData || isReachingStart) return;
      // if (afterData.data.transactions.length < MAX_RESULT_SIZE) {
      setStart(undefined)
      // }
    },
  };

  const transactions = !afterData
    ? []
    : (afterData || lastAfter).data.transactions;
  if (loadingAfter) return { loading: true, data: { transactions } };
  if (afterData) {
    return { ok: true, data: { transactions }, ...pagination };
  }
  return { loading: true };
}
