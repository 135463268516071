import { Fragment, VNode, h } from "preact";
import { LabelWithTooltipMaybeRequired, UIFormProps } from "./InputLine.js";
import { useField } from "./useField.js";

export function InputFile<T extends object, K extends keyof T>(
  props: { maxBites: number; accept?: string } & UIFormProps<T, K>,
): VNode {
  const {
    name,
    label,
    placeholder,
    tooltip,
    required,
    help,
    maxBites,
    accept,
  } = props;
  const { value, onChange, state } = useField<T, K>(name);

  if (state.hidden) {
    return <div />;
  }
  return (
    <div class="col-span-full">
      <LabelWithTooltipMaybeRequired
        label={label}
        tooltip={tooltip}
        required={required}
      />
      {!value || !(value as string).startsWith("data:image/") ? (
        <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 py-1">
          <div class="text-center">
            <svg
              class="mx-auto h-12 w-12 text-gray-300"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                clip-rule="evenodd"
              />
            </svg>
            <div class="my-2 flex text-sm leading-6 text-gray-600">
              <label
                for="file-upload"
                class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  class="sr-only"
                  accept={accept}
                  onChange={(e) => {
                    const f: FileList | null = e.currentTarget.files;
                    if (!f || f.length != 1) {
                      return onChange(undefined!);
                    }
                    if (f[0].size > maxBites) {
                      return onChange(undefined!);
                    }
                    return f[0].arrayBuffer().then((b) => {
                      const b64 = window.btoa(
                        new Uint8Array(b).reduce(
                          (data, byte) => data + String.fromCharCode(byte),
                          "",
                        ),
                      );
                      return onChange(`data:${f[0].type};base64,${b64}` as any);
                    });
                  }}
                />
              </label>
              {/* <p class="pl-1">or drag and drop</p> */}
            </div>
          </div>
        </div>
      ) : (
        <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 relative">
          <img
            src={value as string}
            class=" h-24 w-full object-cover relative"
          />

          <div
            class="opacity-0 hover:opacity-70 duration-300 absolute rounded-lg border inset-0 z-10 flex justify-center text-xl items-center bg-black text-white cursor-pointer "
            onClick={() => {
              onChange(undefined!);
            }}
          >
            Clear
          </div>
        </div>
      )}
      {help && <p class="text-xs leading-5 text-gray-600 mt-2">{help}</p>}
    </div>
  );
}
