/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { HttpError, HttpResponseOk, HttpResponsePaginated, utils } from "@gnu-taler/web-util/browser";
import { AbsoluteTime, AmountJson, PaytoUriIBAN, PaytoUriTalerBank } from "@gnu-taler/taler-util";
import { Loading } from "../../components/Loading.js";
import { useComponentState } from "./state.js";
import { ReadyView, InvalidIbanView } from "./views.js";
import { VNode } from "preact";
import { LoginForm } from "../LoginForm.js";
import { ErrorLoading } from "../../components/ErrorLoading.js";

export interface Props {
  account: string;
  onLoadNotOk: <T>(
    error: HttpResponsePaginated<T, SandboxBackend.SandboxError>,
  ) => VNode;
  goToBusinessAccount: () => void;
  goToConfirmOperation: (id: string) => void;
}

export type State = State.Loading | State.LoadingError | State.Ready | State.InvalidIban | State.UserNotFound;

export namespace State {
  export interface Loading {
    status: "loading";
    error: undefined;
  }

  export interface LoadingError {
    status: "loading-error";
    error: HttpError<SandboxBackend.SandboxError>;
  }

  export interface BaseInfo {
    error: undefined;
  }

  export interface Ready extends BaseInfo {
    status: "ready";
    error: undefined;
    account: string,
    limit: AmountJson,
    goToBusinessAccount: () => void;
    goToConfirmOperation: (id: string) => void;
  }

  export interface InvalidIban {
    status: "invalid-iban",
    error: HttpResponseOk<SandboxBackend.CoreBank.AccountData>;
  }

  export interface UserNotFound {
    status: "error-user-not-found",
    error: HttpError<any>;
    onRegister?: () => void;
  }
}

export interface Transaction {
  negative: boolean;
  counterpart: string;
  when: AbsoluteTime;
  amount: AmountJson | undefined;
  subject: string;
}

const viewMapping: utils.StateViewMap<State> = {
  loading: Loading,
  "error-user-not-found": LoginForm,
  "invalid-iban": InvalidIbanView,
  "loading-error": ErrorLoading,
  ready: ReadyView,
};

export const AccountPage = utils.compose(
  (p: Props) => useComponentState(p),
  viewMapping,
);
