/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { createHashHistory } from "history";
import { Fragment, VNode, h } from "preact";
import { Route, Router, route } from "preact-router";
import { useEffect } from "preact/hooks";
import { useBackendContext } from "../context/backend.js";
import { BankFrame } from "../pages/BankFrame.js";
import { HomePage, WithdrawalOperationPage } from "../pages/HomePage.js";
import { LoginForm } from "../pages/LoginForm.js";
import { PublicHistoriesPage } from "../pages/PublicHistoriesPage.js";
import { RegistrationPage } from "../pages/RegistrationPage.js";
import { AdminHome } from "../pages/admin/Home.js";
import { BusinessAccount } from "../pages/business/Home.js";
import { bankUiSettings } from "../settings.js";

export function Routing(): VNode {
  const history = createHashHistory();
  const backend = useBackendContext();
  const {i18n} = useTranslationContext();
  
  if (backend.state.status === "loggedOut") {
    return <BankFrame >
      <Router history={history}>
        <Route
          path="/login"
          component={() => (
            <Fragment>
              <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 class="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">{i18n.str`Welcome to ${bankUiSettings.bankName}!`}</h2>
              </div>

              <LoginForm
                onRegister={() => {
                  route("/register");
                }}
              />
            </Fragment>
          )}
        />
        <Route
          path="/public-accounts"
          component={() => <PublicHistoriesPage />}
        />
        <Route
          path="/operation/:wopid"
          component={({ wopid }: { wopid: string }) => (
            <WithdrawalOperationPage
              operationId={wopid}
              onContinue={() => {
                route("/account");
              }}
            />
          )}
        />
        {bankUiSettings.allowRegistrations &&
          <Route
            path="/register"
            component={() => (
              <RegistrationPage
                onComplete={() => {
                  route("/account");
                }}
                onCancel={() => {
                  route("/account");
                }}
              />
            )}
          />
        }
        <Route default component={Redirect} to="/login" />
      </Router>
    </BankFrame>
  }
  const { isUserAdministrator, username } = backend.state

  return (
    <BankFrame account={backend.state.username}>
      <Router history={history}>
        <Route
          path="/operation/:wopid"
          component={({ wopid }: { wopid: string }) => (
            <WithdrawalOperationPage
              operationId={wopid}
              onContinue={() => {
                route("/account");
              }}
            />
          )}
        />
        <Route
          path="/public-accounts"
          component={() => <PublicHistoriesPage />}
        />
        <Route
          path="/account"
          component={() => {
            if (isUserAdministrator) {
              return <AdminHome
                onRegister={() => {
                  route("/register");
                }}
              />;
            } else {
              return <HomePage
                account={username}
                goToConfirmOperation={(wopid) => {
                  route(`/operation/${wopid}`);
                }}
                goToBusinessAccount={() => {
                  route("/business");
                }}
                onRegister={() => {
                  route("/register");
                }}
              />
            }
          }}
        />
        <Route
          path="/business"
          component={() => (
            <BusinessAccount
              account={username}
              onClose={() => {
                route("/account");
              }}
              onRegister={() => {
                route("/register");
              }}
              onLoadNotOk={() => {
                route("/account");
              }}
            />
          )}
        />
        <Route default component={Redirect} to="/account" />
      </Router>
    </BankFrame>
  );
}

function Redirect({ to }: { to: string }): VNode {
  useEffect(() => {
    route(to, true);
  }, []);
  return <div>being redirected to {to}</div>;
}

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}
