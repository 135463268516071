/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  HttpResponse,
  HttpResponseOk,
  HttpResponsePaginated,
  RequestError,
  useApiContext,
} from "@gnu-taler/web-util/browser";
import { useEffect, useMemo, useState } from "preact/hooks";
import { useBackendContext } from "../context/backend.js";
import { MAX_RESULT_SIZE, PAGE_SIZE } from "../utils.js";
import {
  getInitialBackendBaseURL,
  useAuthenticatedBackend,
  useMatchMutate,
} from "./backend.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import _useSWR, { SWRHook } from "swr";
import { AmountJson, Amounts } from "@gnu-taler/taler-util";
import { AccessToken } from "./useCredentialsChecker.js";
const useSWR = _useSWR as unknown as SWRHook;

export function useAdminAccountAPI(): AdminAccountAPI {
  const { request } = useAuthenticatedBackend();
  const mutateAll = useMatchMutate();
  const { state, logIn } = useBackendContext();
  if (state.status === "loggedOut") {
    throw Error("access-api can't be used when the user is not logged In");
  }

  const createAccount = async (
    data: SandboxBackend.Circuit.CircuitAccountRequest,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/accounts`, {
      method: "POST",
      data,
      contentType: "json",
    });
    await mutateAll(/.*circuit-api\/accounts.*/);
    return res;
  };

  const updateAccount = async (
    account: string,
    data: SandboxBackend.Circuit.CircuitAccountReconfiguration,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/accounts/${account}`, {
      method: "PATCH",
      data,
      contentType: "json",
    });
    await mutateAll(/.*circuit-api\/accounts.*/);
    return res;
  };
  const deleteAccount = async (
    account: string,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/accounts/${account}`, {
      method: "DELETE",
      contentType: "json",
    });
    await mutateAll(/.*circuit-api\/accounts.*/);
    return res;
  };
  const changePassword = async (
    account: string,
    data: SandboxBackend.Circuit.AccountPasswordChange,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/accounts/${account}/auth`, {
      method: "PATCH",
      data,
      contentType: "json",
    });
    if (account === state.username) {
      await mutateAll(/.*/);
      logIn({
        username: account,
        //FIXME: change password api
        token: data.new_password as AccessToken,
      });
    }
    return res;
  };

  return { createAccount, deleteAccount, updateAccount, changePassword };
}

export function useCircuitAccountAPI(): CircuitAccountAPI {
  const { request } = useAuthenticatedBackend();
  const mutateAll = useMatchMutate();
  const { state } = useBackendContext();
  if (state.status === "loggedOut") {
    throw Error("access-api can't be used when the user is not logged In");
  }
  const account = state.username;

  const updateAccount = async (
    data: SandboxBackend.Circuit.CircuitAccountReconfiguration,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/accounts/${account}`, {
      method: "PATCH",
      data,
      contentType: "json",
    });
    await mutateAll(/.*circuit-api\/accounts.*/);
    return res;
  };
  const changePassword = async (
    data: SandboxBackend.Circuit.AccountPasswordChange,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/accounts/${account}/auth`, {
      method: "PATCH",
      data,
      contentType: "json",
    });
    return res;
  };

  const createCashout = async (
    data: SandboxBackend.Circuit.CashoutRequest,
  ): Promise<HttpResponseOk<SandboxBackend.Circuit.CashoutPending>> => {
    const res = await request<SandboxBackend.Circuit.CashoutPending>(
      `circuit-api/cashouts`,
      {
        method: "POST",
        data,
        contentType: "json",
      },
    );
    return res;
  };

  const confirmCashout = async (
    cashoutId: string,
    data: SandboxBackend.Circuit.CashoutConfirm,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(
      `circuit-api/cashouts/${cashoutId}/confirm`,
      {
        method: "POST",
        data,
        contentType: "json",
      },
    );
    await mutateAll(/.*circuit-api\/cashout.*/);
    return res;
  };

  const abortCashout = async (
    cashoutId: string,
  ): Promise<HttpResponseOk<void>> => {
    const res = await request<void>(`circuit-api/cashouts/${cashoutId}/abort`, {
      method: "POST",
      contentType: "json",
    });
    await mutateAll(/.*circuit-api\/cashout.*/);
    return res;
  };

  return {
    updateAccount,
    changePassword,
    createCashout,
    confirmCashout,
    abortCashout,
  };
}

export interface AdminAccountAPI {
  createAccount: (
    data: SandboxBackend.Circuit.CircuitAccountRequest,
  ) => Promise<HttpResponseOk<void>>;
  deleteAccount: (account: string) => Promise<HttpResponseOk<void>>;

  updateAccount: (
    account: string,
    data: SandboxBackend.Circuit.CircuitAccountReconfiguration,
  ) => Promise<HttpResponseOk<void>>;
  changePassword: (
    account: string,
    data: SandboxBackend.Circuit.AccountPasswordChange,
  ) => Promise<HttpResponseOk<void>>;
}

export interface CircuitAccountAPI {
  updateAccount: (
    data: SandboxBackend.Circuit.CircuitAccountReconfiguration,
  ) => Promise<HttpResponseOk<void>>;
  changePassword: (
    data: SandboxBackend.Circuit.AccountPasswordChange,
  ) => Promise<HttpResponseOk<void>>;
  createCashout: (
    data: SandboxBackend.Circuit.CashoutRequest,
  ) => Promise<HttpResponseOk<SandboxBackend.Circuit.CashoutPending>>;
  confirmCashout: (
    id: string,
    data: SandboxBackend.Circuit.CashoutConfirm,
  ) => Promise<HttpResponseOk<void>>;
  abortCashout: (id: string) => Promise<HttpResponseOk<void>>;
}

async function getBusinessStatus(
  request: ReturnType<typeof useApiContext>["request"],
  username: string,
  token: AccessToken,
): Promise<boolean> {
  try {
    const url = getInitialBackendBaseURL();
    const result = await request<SandboxBackend.Circuit.CircuitAccountData>(
      url,
      `circuit-api/accounts/${username}`,
      { token },
    );
    return result.ok;
  } catch (error) {
    return false;
  }
}

async function getEstimationByCredit(
  request: ReturnType<typeof useApiContext>["request"],
  basicAuth: { username: string; password: string },
): Promise<boolean> {
  try {
    const url = getInitialBackendBaseURL();
    const result = await request<
      HttpResponseOk<SandboxBackend.Circuit.CircuitAccountData>
    >(url, `circuit-api/accounts/${basicAuth.username}`, { basicAuth });
    return result.ok;
  } catch (error) {
    return false;
  }
}

export type TransferCalculation = {
  debit: AmountJson;
  credit: AmountJson;
  beforeFee: AmountJson;
};
type EstimatorFunction = (
  amount: AmountJson,
  sellFee: AmountJson,
  sellRate: number,
) => Promise<TransferCalculation>;

type CashoutEstimators = {
  estimateByCredit: EstimatorFunction;
  estimateByDebit: EstimatorFunction;
};

export function useEstimator(): CashoutEstimators {
  const { state } = useBackendContext();
  const { request } = useApiContext();
  const creds =
    state.status !== "loggedIn"
      ? undefined
      : state.token;
  return {
    estimateByCredit: async (amount, fee, rate) => {
      const zeroBalance = Amounts.zeroOfCurrency(fee.currency);
      const zeroFiat = Amounts.zeroOfCurrency(fee.currency);
      const zeroCalc = {
        debit: zeroBalance,
        credit: zeroFiat,
        beforeFee: zeroBalance,
      };
      const url = getInitialBackendBaseURL();
      const result = await request<SandboxBackend.Circuit.CashoutEstimate>(
        url,
        `circuit-api/cashouts/estimates`,
        {
          token: creds,
          params: {
            amount_credit: Amounts.stringify(amount),
          },
        },
      );
      // const credit = Amounts.parseOrThrow(result.data.data.amount_credit);
      const credit = amount;
      const _credit = { ...credit, currency: fee.currency };
      const beforeFee = Amounts.sub(_credit, fee).amount;

      const debit = Amounts.parseOrThrow(result.data.amount_debit);
      return {
        debit,
        beforeFee,
        credit,
      };
    },
    estimateByDebit: async (amount, fee, rate) => {
      const zeroBalance = Amounts.zeroOfCurrency(fee.currency);
      const zeroFiat = Amounts.zeroOfCurrency(fee.currency);
      const zeroCalc = {
        debit: zeroBalance,
        credit: zeroFiat,
        beforeFee: zeroBalance,
      };
      const url = getInitialBackendBaseURL();
      const result = await request<SandboxBackend.Circuit.CashoutEstimate>(
        url,
        `circuit-api/cashouts/estimates`,
        {
          token: creds,
          params: {
            amount_debit: Amounts.stringify(amount),
          },
        },
      );
      const credit = Amounts.parseOrThrow(result.data.amount_credit);
      const _credit = { ...credit, currency: fee.currency };
      const debit = amount;
      const beforeFee = Amounts.sub(_credit, fee).amount;
      return {
        debit,
        beforeFee,
        credit,
      };
    },
  };
}

export function useBusinessAccountFlag(): boolean | undefined {
  const [isBusiness, setIsBusiness] = useState<boolean | undefined>();
  const { state } = useBackendContext();
  const { request } = useApiContext();
  const creds =
    state.status !== "loggedIn"
      ? undefined
      : {user: state.username, token: state.token};

  useEffect(() => {
    if (!creds) return;
    getBusinessStatus(request, creds.user, creds.token)
      .then((result) => {
        setIsBusiness(result);
      })
      .catch((error) => {
        setIsBusiness(false);
      });
  });

  return isBusiness;
}

export function useBusinessAccountDetails(
  account: string,
): HttpResponse<
  SandboxBackend.Circuit.CircuitAccountData,
  SandboxBackend.SandboxError
> {
  const { fetcher } = useAuthenticatedBackend();

  const { data, error } = useSWR<
    HttpResponseOk<SandboxBackend.Circuit.CircuitAccountData>,
    RequestError<SandboxBackend.SandboxError>
  >([`circuit-api/accounts/${account}`], fetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    errorRetryCount: 0,
    errorRetryInterval: 1,
    shouldRetryOnError: false,
    keepPreviousData: true,
  });

  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}

export function useRatiosAndFeeConfig(): HttpResponse<
  SandboxBackend.Circuit.Config,
  SandboxBackend.SandboxError
> {
  const { fetcher } = useAuthenticatedBackend();

  const { data, error } = useSWR<
    HttpResponseOk<SandboxBackend.Circuit.Config>,
    RequestError<SandboxBackend.SandboxError>
  >([`circuit-api/config`], fetcher, {
    refreshInterval: 60 * 1000,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    errorRetryCount: 0,
    errorRetryInterval: 1,
    shouldRetryOnError: false,
    keepPreviousData: true,
  });

  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}

interface PaginationFilter {
  account?: string;
  page?: number;
}

export function useBusinessAccounts(
  args?: PaginationFilter,
): HttpResponsePaginated<
  SandboxBackend.Circuit.CircuitAccounts,
  SandboxBackend.SandboxError
> {
  const { sandboxAccountsFetcher } = useAuthenticatedBackend();
  const [page, setPage] = useState(0);

  const {
    data: afterData,
    error: afterError,
    // isValidating: loadingAfter,
  } = useSWR<
    HttpResponseOk<SandboxBackend.Circuit.CircuitAccounts>,
    RequestError<SandboxBackend.SandboxError>
  >(
    [`accounts`, args?.page, PAGE_SIZE, args?.account],
    sandboxAccountsFetcher,
    {
      refreshInterval: 0,
      refreshWhenHidden: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      errorRetryCount: 0,
      errorRetryInterval: 1,
      shouldRetryOnError: false,
      keepPreviousData: true,
    },
  );

  // const [lastAfter, setLastAfter] = useState<
  //   HttpResponse<SandboxBackend.Circuit.CircuitAccounts, SandboxBackend.SandboxError>
  // >({ loading: true });

  // useEffect(() => {
  //   if (afterData) setLastAfter(afterData);
  // }, [afterData]);

  // if the query returns less that we ask, then we have reach the end or beginning
  const isReachingEnd =
    afterData && afterData.data?.customers?.length < PAGE_SIZE;
  const isReachingStart = false;

  const pagination = {
    isReachingEnd,
    isReachingStart,
    loadMore: () => {
      if (!afterData || isReachingEnd) return;
      if (afterData.data?.customers?.length < MAX_RESULT_SIZE) {
        setPage(page + 1);
      }
    },
    loadMorePrev: () => {
      null;
    },
  };

  const result = useMemo(() => {
    const customers = !afterData ? [] : afterData?.data?.customers ?? [];
    return { ok: true as const, data: { customers }, ...pagination };
  }, [afterData?.data]);

  if (afterError) return afterError.cause;
  if (afterData) {
    return result;
  }

  // if (loadingAfter)
  //   return { loading: true, data: { customers } };
  // if (afterData) {
  //   return { ok: true, data: { customers }, ...pagination };
  // }
  return { loading: true };
}

export function useCashouts(
  account: string,
): HttpResponse<
  SandboxBackend.Circuit.CashoutStatusResponseWithId[],
  SandboxBackend.SandboxError
> {
  const { sandboxCashoutFetcher, multiFetcher } = useAuthenticatedBackend();

  const { data: list, error: listError } = useSWR<
    HttpResponseOk<SandboxBackend.Circuit.Cashouts>,
    RequestError<SandboxBackend.SandboxError>
  >([`circuit-api/cashouts`, account], sandboxCashoutFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
  });

  const paths = ((list?.data && list?.data.cashouts) || []).map(
    (cashoutId) => `circuit-api/cashouts/${cashoutId}`,
  );
  const { data: cashouts, error: productError } = useSWR<
    HttpResponseOk<SandboxBackend.Circuit.CashoutStatusResponse>[],
    RequestError<SandboxBackend.SandboxError>
  >([paths], multiFetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
  });

  if (listError) return listError.cause;
  if (productError) return productError.cause;

  if (cashouts) {
    const dataWithId = cashouts.map((d) => {
      //take the id from the queried url
      return {
        ...d.data,
        id: d.info?.url.replace(/.*\/cashouts\//, "") || "",
      };
    });
    return { ok: true, data: dataWithId };
  }
  return { loading: true };
}

export function useCashoutDetails(
  id: string,
): HttpResponse<
  SandboxBackend.Circuit.CashoutStatusResponse,
  SandboxBackend.SandboxError
> {
  const { fetcher } = useAuthenticatedBackend();

  const { data, error } = useSWR<
    HttpResponseOk<SandboxBackend.Circuit.CashoutStatusResponse>,
    RequestError<SandboxBackend.SandboxError>
  >([`circuit-api/cashouts/${id}`], fetcher, {
    refreshInterval: 0,
    refreshWhenHidden: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    errorRetryCount: 0,
    errorRetryInterval: 1,
    shouldRetryOnError: false,
    keepPreviousData: true,
  });

  if (data) return data;
  if (error) return error.cause;
  return { loading: true };
}
