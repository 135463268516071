/*
 This file is part of GNU Taler
 (C) 2022 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { stringifyWithdrawUri } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import { QR } from "../../components/QR.js";
import { ShowInputErrorLabel } from "../../components/ShowInputErrorLabel.js";
import { useSettings } from "../../hooks/settings.js";
import { undefinedIfEmpty } from "../../utils.js";
import { State } from "./index.js";

export function InvalidPaytoView({ payto, onClose }: State.InvalidPayto) {
  return (
    <div>Payto from server is not valid &quot;{payto}&quot;</div>
  );
}
export function InvalidWithdrawalView({ uri, onClose }: State.InvalidWithdrawal) {
  return (
    <div>Withdrawal uri from server is not valid &quot;{uri}&quot;</div>
  );
}
export function InvalidReserveView({ reserve, onClose }: State.InvalidReserve) {
  return (
    <div>Reserve from server is not valid &quot;{reserve}&quot;</div>
  );
}

export function NeedConfirmationView({ error, onAbort, onConfirm, busy }: State.NeedConfirmation) {
  const { i18n } = useTranslationContext()

  const captchaNumbers = useMemo(() => {
    return {
      a: Math.floor(Math.random() * 10),
      b: Math.floor(Math.random() * 10),
    };
  }, []);
  const [captchaAnswer, setCaptchaAnswer] = useState<string | undefined>();
  const answer = parseInt(captchaAnswer ?? "", 10);
  const errors = undefinedIfEmpty({
    answer: !captchaAnswer
      ? i18n.str`Answer the question before continue`
      : Number.isNaN(answer)
        ? i18n.str`The answer should be a number`
        : answer !== captchaNumbers.a + captchaNumbers.b
          ? i18n.str`The answer "${answer}" to "${captchaNumbers.a} + ${captchaNumbers.b}" is wrong.`
          : undefined,
  }) ?? (busy ? {} as Record<string, undefined> : undefined);

  return (
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-base font-semibold text-gray-900">
          <i18n.Translate>Confirm the withdrawal operation</i18n.Translate>
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <div class="px-4 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-3">

            <label class={"relative sm:col-span-2 flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-noneborder-indigo-600 ring-2 ring-indigo-600"}>
              <input type="radio" name="project-type" value="Newsletter" class="sr-only" aria-labelledby="project-type-0-label" aria-describedby="project-type-0-description-0 project-type-0-description-1" />
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <span id="project-type-0-label" class="block text-sm font-medium text-gray-900 ">
                    <i18n.Translate>challenge response test</i18n.Translate>
                  </span>
                </span>
              </span>
              <svg class="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
            </label>


            <label class="relative flex cursor-pointer rounded-lg border bg-gray-100  p-4 shadow-sm focus:outline-none border-gray-300">
              <input type="radio" name="project-type" value="Existing Customers" class="sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-1-description-0 project-type-1-description-1" />
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <span id="project-type-1-label" class="block text-sm font-medium text-gray-900">
                    <i18n.Translate>using SMS</i18n.Translate>
                  </span>
                  <span id="project-type-1-description-0" class="mt-1 flex items-center text-sm text-gray-500">
                    <i18n.Translate>not available</i18n.Translate>
                  </span>
                </span>
              </span>
              <svg class="h-5 w-5 text-indigo-600 hidden" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
            </label>

            <label class="relative flex cursor-pointer rounded-lg border bg-gray-100 p-4 shadow-sm focus:outline-none border-gray-300">
              <input type="radio" name="project-type" value="Existing Customers" class="sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-1-description-0 project-type-1-description-1" />
              <span class="flex flex-1">
                <span class="flex flex-col">
                  <span id="project-type-1-label" class="block text-sm font-medium text-gray-900">
                    <i18n.Translate>one time password</i18n.Translate>
                  </span>
                  <span id="project-type-1-description-0" class="mt-1 flex items-center text-sm text-gray-500">
                    <i18n.Translate>not available</i18n.Translate>
                  </span>
                </span>
              </span>
              <svg class="h-5 w-5 text-indigo-600 hidden" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
              </svg>
            </label>
          </div>
        </div>
        <div class="mt-3 text-sm leading-6">

          <form
            class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            autoCapitalize="none"
            autoCorrect="off"
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <div class="px-4 py-6 sm:p-8">
              <label for="withdraw-amount">{i18n.str`What is`}&nbsp;
                <em>
                  {captchaNumbers.a}&nbsp;+&nbsp;{captchaNumbers.b}
                </em>
                ?
              </label>
              <div class="mt-2">
                <div class="relative rounded-md shadow-sm">
                  <input
                    type="text"
                    // class="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    aria-describedby="answer"
                    autoFocus
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={captchaAnswer ?? ""}
                    required

                    name="answer"
                    id="answer"
                    autocomplete="off"
                    onChange={(e): void => {
                      setCaptchaAnswer(e.currentTarget.value)
                    }}
                  />
                </div>
                <ShowInputErrorLabel message={errors?.answer} isDirty={captchaAnswer !== undefined} />
              </div>
            </div>
            <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button type="button" class="text-sm font-semibold leading-6 text-gray-900"
                onClick={onAbort}
              >
                <i18n.Translate>Cancel</i18n.Translate></button>
              <button type="submit"
                class="disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={!!errors}
                onClick={(e) => {
                  e.preventDefault()
                  onConfirm()
                }}
              >
                <i18n.Translate>Transfer</i18n.Translate>
              </button>
            </div>

          </form>
        </div>
        <div class="px-4 mt-4 ">
          {/* <div class="w-full">
          <div class="px-4 sm:px-0 text-sm">
            <p><i18n.Translate>Wire transfer details</i18n.Translate></p>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              {((): VNode => {
                switch (details.account.targetType) {
                  case "iban": {
                    const p = details.account as PaytoUriIBAN
                    const name = p.params["receiver-name"]
                    return <Fragment>
                      <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Exchange account</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{p.iban}</dd>
                      </div>
                      {name &&
                        <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt class="text-sm font-medium leading-6 text-gray-900">Exchange name</dt>
                          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{p.params["receiver-name"]}</dd>
                        </div>
                      }
                    </Fragment>
                  }
                  case "x-taler-bank": {
                    const p = details.account as PaytoUriTalerBank
                    const name = p.params["receiver-name"]
                    return <Fragment>
                      <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Exchange account</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{p.account}</dd>
                      </div>
                      {name &&
                        <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt class="text-sm font-medium leading-6 text-gray-900">Exchange name</dt>
                          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{p.params["receiver-name"]}</dd>
                        </div>
                      }
                    </Fragment>
                  }
                  default:
                    return <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Exchange account</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{details.account.targetPath}</dd>
                    </div>

                }
              })()}
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">Withdrawal identification</dt>
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 break-words">{details.reserve}</dd>
              </div>
              <div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-sm font-medium leading-6 text-gray-900">Amount</dt>
                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">To be added</dd>
                // {/* Amounts.stringifyValue(details.amount) 
              </div>
            </dl>
          </div>
        </div> */}

        </div>
      </div>
    </div>

  );
}
export function AbortedView({ error, onClose }: State.Aborted) {
  return (
    <div>aborted</div>
  );
}

export function ConfirmedView({ error, onClose }: State.Confirmed) {
  const { i18n } = useTranslationContext();
  const [settings, updateSettings] = useSettings()
  return (
    <Fragment>

      <div class="relative ml-auto mr-auto transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all ">

        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
            <i18n.Translate>Withdrawal confirmed</i18n.Translate>
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              <i18n.Translate>
                The wire transfer to the Taler operator has been initiated. You will soon receive the requested amount in your Taler wallet.
              </i18n.Translate>
            </p>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="flex items-center justify-between">
          <span class="flex flex-grow flex-col">
            <span class="text-sm text-black font-medium leading-6 " id="availability-label">
              <i18n.Translate>Do not show this again</i18n.Translate>
            </span>
          </span>
          <button type="button" data-enabled={!settings.showWithdrawalSuccess} class="bg-indigo-600 data-[enabled=false]:bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2" role="switch" aria-checked="false" aria-labelledby="availability-label" aria-describedby="availability-description"
            onClick={() => {
              updateSettings("showWithdrawalSuccess", !settings.showWithdrawalSuccess);
            }}>
            <span aria-hidden="true" data-enabled={!settings.showWithdrawalSuccess} class="translate-x-5 data-[enabled=false]:translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
          </button>
        </div>
      </div>
      <div class="mt-5 sm:mt-6">
        <button type="button"
          class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={async (e) => {
            e.preventDefault();
            onClose()
          }}>
          <i18n.Translate>Close</i18n.Translate>
        </button>
      </div>
    </Fragment>

  );
}

export function ReadyView({ uri, onClose }: State.Ready): VNode<{}> {
  const { i18n } = useTranslationContext();

  useEffect(() => {
    //Taler Wallet WebExtension is listening to headers response and tab updates.
    //In the SPA there is no header response with the Taler URI so
    //this hack manually triggers the tab update after the QR is in the DOM.
    // WebExtension will be using
    // https://developer.chrome.com/docs/extensions/reference/tabs/#event-onUpdated
    document.title = `${document.title} ${uri.withdrawalOperationId}`;
  }, []);
  const talerWithdrawUri = stringifyWithdrawUri(uri);
  return <Fragment>
    <div class="flex justify-end mt-4">
      <button type="button"
        class="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
        onClick={() => {
          onClose()
        }}
      >
        Cancel
      </button>
    </div>

    <div class="bg-white shadow sm:rounded-lg mt-4">
      <div class="p-4">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          <i18n.Translate>On this device</i18n.Translate>
        </h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm text-gray-500">
            <p>
              <i18n.Translate>If you are using a desktop browser you can open the popup now or click the link if you have the "Inject Taler support" option enabled.</i18n.Translate>
            </p>
          </div>
          <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <a href={talerWithdrawUri}
              class="inline-flex items-center  disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <i18n.Translate>Start</i18n.Translate>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white shadow sm:rounded-lg mt-2">
      <div class="p-4">
        <h3 class="text-base font-semibold leading-6 text-gray-900">
          <i18n.Translate>On a mobile phone</i18n.Translate>
        </h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm text-gray-500">
            <p>
              <i18n.Translate>Scan the QR code with your mobile device.</i18n.Translate>
            </p>
          </div>
        </div>
        <div class="mt-2 max-w-md ml-auto mr-auto">
          <QR text={talerWithdrawUri} />
        </div>
      </div>
    </div>

  </Fragment>

}
