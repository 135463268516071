import { HttpStatusCode, TranslatedString } from "@gnu-taler/taler-util";
import { ErrorType, HttpResponsePaginated, RequestError, notify, notifyError, useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { ShowInputErrorLabel } from "../components/ShowInputErrorLabel.js";
import { useAdminAccountAPI, useBusinessAccountDetails } from "../hooks/circuit.js";
import { buildRequestErrorMessage, undefinedIfEmpty } from "../utils.js";
import { doAutoFocus } from "./PaytoWireTransferForm.js";

export function UpdateAccountPassword({
  account,
  onCancel,
  onUpdateSuccess,
  onLoadNotOk,
  focus,
}: {
  onLoadNotOk: <T>(
    error: HttpResponsePaginated<T, SandboxBackend.SandboxError>,
  ) => VNode;
  onCancel: () => void;
  focus?: boolean,
  onUpdateSuccess: () => void;
  account: string;
}): VNode {
  const { i18n } = useTranslationContext();
  const result = useBusinessAccountDetails(account);
  const { changePassword } = useAdminAccountAPI();
  const [password, setPassword] = useState<string | undefined>();
  const [repeat, setRepeat] = useState<string | undefined>();

  if (!result.ok) {
    if (result.loading || result.type === ErrorType.TIMEOUT) {
      return onLoadNotOk(result);
    }
    if (result.status === HttpStatusCode.NotFound) {
      return <div>account not found</div>;
    }
    return onLoadNotOk(result);
  }

  const errors = undefinedIfEmpty({
    password: !password ? i18n.str`required` : undefined,
    repeat: !repeat
      ? i18n.str`required`
      : password !== repeat
        ? i18n.str`password doesn't match`
        : undefined,
  });

  async function doChangePassword() {
    if (!!errors || !password) return;
    try {
      const r = await changePassword(account, {
        new_password: password,
      });
      onUpdateSuccess();
    } catch (error) {
      if (error instanceof RequestError) {
        notify(buildRequestErrorMessage(i18n, error.cause));
      } else {
        notifyError(i18n.str`Operation failed, please report`, (error instanceof Error
          ? error.message
          : JSON.stringify(error)) as TranslatedString)
      }
    }
  }

  return (
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3 bg-gray-100 my-4 px-4 pb-4 rounded-lg">
      <div class="px-4 sm:px-0">
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          <i18n.Translate>Update password for account "{account}"</i18n.Translate>
        </h2>
      </div>
      <form
        class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        autoCapitalize="none"
        autoCorrect="off"
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <div class="px-4 py-6 sm:p-8">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div class="sm:col-span-5">
              <label
                class="block text-sm font-medium leading-6 text-gray-900"
                for="password"
              >
                {i18n.str`New password`}
              </label>
              <div class="mt-2">
                <input
                  ref={focus ? doAutoFocus : undefined}
                  type="password"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 data-[error=true]:ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  name="password"
                  id="password"
                  data-error={!!errors?.password && password !== undefined}
                  value={password ?? ""}
                  onChange={(e) => {
                    setPassword(e.currentTarget.value)
                  }}
                  // placeholder=""
                  autocomplete="off"
                />
                <ShowInputErrorLabel
                  message={errors?.password}
                  isDirty={password !== undefined}
                />
              </div>
              {/* <p class="mt-2 text-sm text-gray-500" >
              <i18n.Translate>user </i18n.Translate>
            </p> */}
            </div>

            <div class="sm:col-span-5">
              <label
                class="block text-sm font-medium leading-6 text-gray-900"
                for="repeat"
              >
                {i18n.str`Type it again`}
              </label>
              <div class="mt-2">
                <input
                  type="password"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 data-[error=true]:ring-red-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  name="repeat"
                  id="repeat"
                  data-error={!!errors?.repeat && repeat !== undefined}
                  value={repeat ?? ""}
                  onChange={(e) => {
                    setRepeat(e.currentTarget.value)
                  }}
                  // placeholder=""
                  autocomplete="off"
                />
                <ShowInputErrorLabel
                  message={errors?.repeat}
                  isDirty={repeat !== undefined}
                />
              </div>
              <p class="mt-2 text-sm text-gray-500" >
                <i18n.Translate>repeat the same password</i18n.Translate>
              </p>
            </div>



          </div>
        </div>
        <div class="flex items-center justify-between gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          {onCancel ?
            <button type="button" class="text-sm font-semibold leading-6 text-gray-900"
              onClick={onCancel}
            >
              <i18n.Translate>Cancel</i18n.Translate>
            </button>
            : <div />
          }
          <button type="submit"
            class="disabled:opacity-50 disabled:cursor-default cursor-pointer rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={!!errors}
            onClick={(e) => {
              e.preventDefault()
              doChangePassword()
            }}
          >
            <i18n.Translate>Change</i18n.Translate>
          </button>
        </div>
      </form>
    </div>

  );
}